<template>
  <v-dialog v-model="dialog" width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab dark x-small v-bind="attrs" v-on="on" color="green">
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Farbwähler</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="py-4 text-center">
        <v-color-picker
          v-model="value"
          mode="hexa"
          hide-mode-switch
        ></v-color-picker>

        <template v-if="companyColors">
          <v-btn
            color="primary"
            text
            @click="
              $emit('changed', 'primary');
              dialog = false;
            "
          >
            Primärfarbe setzen
          </v-btn>
          <br />
          <v-btn
            color="primary"
            text
            @click="
              $emit('changed', 'second');
              dialog = false;
            "
          >
            Sekundärfarbe setzen
          </v-btn>
        </template>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          text
          @click="
            $emit('changed', value);
            dialog = false;
          "
        >
          Übernehmen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["color", "companyColors"],

  data() {
    return {
      dialog: false,
      value:
        this.color && this.color !== "null" && this.color !== null
          ? this.color
          : "",
    };
  },
};
</script>
