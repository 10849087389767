<template>
  <div>
    <v-card class="mb-4 grey lighten-5">
      <SliceHeader
        :edit="edit"
        :someSliceInEdit="someSliceInEdit"
        :componentName="element.componentName"
      />

      <v-card-text class="component">
        <div v-if="edit" class="component-edit">
          <draggable
            v-model="selected"
            ghost-class="ghost"
            :move="checkMove"
            handle=".draghandle"
            @start="dragging = true"
            draggable=".is-draggable"
          >
            <template v-if="selected && selected.length > 0">
              <template v-for="(item, index) in selected">
                <div class="is-draggable" :key="item.id">
                  <Item
                    :items="items"
                    :item="item"
                    @remove="removeItem(index)"
                    :key="componentKey"
                  />
                </div>
              </template>
            </template>
          </draggable>

          <v-btn
            v-if="companies_id"
            depressed
            class="my-4"
            color="primary"
            @click="addDialog = true"
          >
            Vertrag hinzufügen
          </v-btn>

          <span v-else>
            <p>
              <strong class="red--text">
                Es ist noch nicht möglich einen Vertrag hinzuzufügen.
              </strong>
            </p>
            <p>
              Erst, wenn das Landingpage-Template einer Kampagne zugewiesen
              wurde, können Verträge hinzugefügt werden, da erst dann eine
              Firmenzuweisung möglich ist.
            </p>
          </span>

          <v-form v-model="valid" class="d-block" id="form" ref="form">
            <fieldset class="px-5 py-5 mb-2">
              <v-row no-gutters>
                <v-col cols="12">
                  <h2>Teaser-Seite</h2>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="teaserTitle"
                    label="Teaser-Überschrift"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="teaserPrice"
                    label="Teaser-Preis (z.B. ab 99,99 €)"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <h3>Text</h3>
                  <v-md-editor
                    v-model="teaserText"
                    height="200px"
                    left-toolbar="h bold italic | ul ol | emoji"
                    right-toolbar="preview fullscreen"
                    :disabled-menus="['h/h1']"
                    mode="edit"
                  ></v-md-editor>
                </v-col>

                <v-col cols="12" class="mt-4">
                  <h2>Angebots-Seite</h2>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="offersTitle"
                    label="Überschrift über den Angeboten"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <h3>Text</h3>
                  <v-md-editor
                    v-model="offersText"
                    height="200px"
                    left-toolbar="h bold italic | ul ol | emoji"
                    right-toolbar="preview fullscreen"
                    :disabled-menus="['h/h1']"
                    mode="edit"
                  ></v-md-editor>
                </v-col>
              </v-row>
            </fieldset>
          </v-form>

          <SectionSettings
            :data="element.data"
            @result="sectionData = $event"
          ></SectionSettings>
        </div>

        <div v-else class="component-preview">
          <v-row no-gutters>
            <v-col cols="12" class="mb-2">
              <h3>Teaser-Seite</h3>
            </v-col>

            <v-col v-if="element.data.teaserTitle" cols="12">
              <strong>Titel:</strong> {{ element.data.teaserTitle }}
            </v-col>

            <v-col v-if="element.data.teaserTitle" cols="12">
              <strong>Preisangabe:</strong> {{ element.data.teaserPrice }}
            </v-col>

            <v-col cols="12">
              <vue-markdown
                v-if="element.data.teaserText"
                :source="element.data.teaserText"
              ></vue-markdown>
            </v-col>
            <v-col cols="12" class="mb-2">
              <h3>Angebot-Seite</h3>
            </v-col>
            <v-col v-if="element.data.offersTitle" cols="12">
              <strong>Titel:</strong> {{ element.data.offersTitle }}
            </v-col>
            <v-col cols="12">
              <vue-markdown
                v-if="element.data.offersText"
                :source="element.data.offersText"
              ></vue-markdown>
            </v-col>
          </v-row>

          <!-- Damit beim Abort nicht der mutierte Wert stehen bleibt, wird hier definitiv auf das Array verwiesen -->
          <ItemPreview
            v-for="item in selected"
            :key="item.id"
            :item="item"
            :items="items"
          />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-chip
          v-if="element.data.sliceIdent"
          x-small
          class="mt-4 float-right grey--text"
          color="grey lighten-2"
        >
          <v-icon x-small left>mdi-information</v-icon>Custom class: .slice_{{
            element.data.sliceIdent
          }}
        </v-chip>

        <v-spacer></v-spacer>

        <div v-if="edit" class="component-onedit-buttons">
          <EditButtons
            @emit="editbuttonAction"
            ShowSafeButton="true"
            ShowAbortButton="true"
          ></EditButtons>
        </div>
        <div v-else class="component-onpreview-buttons">
          <EditButtons
            v-if="!someSliceInEdit"
            @emit="editbuttonAction"
            ShowEditButton="true"
            ShowDeleteButton="true"
          ></EditButtons>

          <EditButtons
            v-else
            @emit="editbuttonAction"
            Disabled="true"
            ShowEditButton="true"
            ShowDeleteButton="true"
          ></EditButtons>
        </div>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="addDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      :key="componentKey"
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title>Wähle Verträge</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="addDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <SelectContracts
            :companies_id="companies_id"
            :selected="selected"
            :key="componentKey"
            @selection="selected = $event"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import draggable from "vuedraggable";
import SliceHeader from "@/components/pages/contentelements/components/ui/sliceHeader";
import EditButtons from "@/components/ui/EditButtons";
import Item from "@/components/pages/contentelements/components/contracts/Item";
import ItemPreview from "@/components/pages/contentelements/components/contracts/ItemPreview";
import SelectContracts from "@/components/pages/contentelements/components/contracts/SelectContracts";
import VueMarkdown from "vue-markdown/src/VueMarkdown"; // neuer Weg (s. oben)
import SectionSettings from "@/components/pages/contentelements/components/SectionSettings";

export default {
  name: "Contracts",

  components: {
    draggable,
    SliceHeader,
    EditButtons,
    Item,
    ItemPreview,
    SelectContracts,
    VueMarkdown,
    SectionSettings,
  },

  // * Da die Komponente Company-Basiert ist, werden hier die companies_id und campaigns_id übergeben
  props: [
    "sliceid",
    "element",
    "someSliceInEdit",
    "prio",
    "companies_id",
    "campaigns_id",
  ],

  data() {
    return {
      componentKey: 0,
      addDialog: false,
      selected: this.splitSelected(), // convert prop (string) to an array
      cleanSelection: this.splitSelected(),
      lazy: true,
      valid: true,
      items: [],
      title: "",
      titleSize: "1",
      edit: false,
      titleSizes: ["1", "2", "3", "4", "5"],
      fetchingData: false,
      teaserTitle: this.element.data.teaserTitle ?? "",
      teaserPrice: this.element.data.teaserPrice ?? "",
      teaserText: this.element.data.teaserText ?? "",
      offersTitle: this.element.data.offersTitle ?? "",
      offersText: this.element.data.offersText ?? "",
      dragging: false,
    };
  },

  created() {
    this.$emit("sliceOnEdit", false); // emit to parent-component

    if (this.companies_id) {
      this.getData();
    }
  },

  methods: {
    splitSelected() {
      if (this.element.data.foreign_ids) {
        return this.element.data.foreign_ids.split(",");
      }
      return [];
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(
        `pay/contracts?filter[companies_id]=${this.companies_id}`
      );
      this.fetchingData = false;

      if (response.status === 200) {
        this.items = response.data.data;
      }
    },

    removeItem(index) {
      this.selected.splice(index, 1);
      this.componentKey += 1;
    },

    /* Clicks werden via $emit action-parameter von der Kindkomponente <EditButtons></EditButtons> übergeben. 
    Der emit-Parameter (value, Object (action+itemId)) wird hier ausgewertet ...
    */

    editbuttonAction(value) {
      if (value.action === "editItem") {
        /* update items may could have changed 
        this avoids from keeping unsaved changes after abort */
        this.title = this.element.data.title;
        this.titleSize = this.element.data.titleSize;
        this.teaserTitle = this.element.data.teaserTitle;
        this.teaserPrice = this.element.data.teaserPrice;
        this.teaserText = this.element.data.teaserText ?? "";
        this.offersTitle = this.element.data.offersTitle;
        this.offersText = this.element.data.offersText ?? "";
        this.edit = true;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      } else if (value.action === "deleteItem") {
        let emitAction = {
          action: "delete",
          sliceId: this.sliceid,
          prio: this.prio,
        };

        this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
      } else if (value.action === "safeItem") {
        this.safeItem();
      } else if (value.action === "abortItem") {
        this.edit = false;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      }
    },

    safeItem() {
      const fields = {
        foreign_ids: this.selected.join(","),
        teaserTitle: this.teaserTitle,
        teaserPrice: this.teaserPrice,
        teaserText: this.teaserText,
        offersTitle: this.offersTitle,
        offersText: this.offersText,
      };

      // add sectionData key/values to fields
      if (this.sectionData) {
        Object.keys(this.sectionData).forEach((key) => {
          fields[key] = this.sectionData[key];
        });
      }

      let emitAction = {
        action: "update",
        sliceId: this.sliceid,
        prio: this.prio,
        fields: fields,
      };

      this.edit = false;

      this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
      this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
    },

    checkMove: function () {
      this.onDrag = true;
      //window.console.log("Future index: " + e.draggedContext.futureIndex);
    },

    handleMove(e) {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */
      this.movingIndex = e.draggedContext.index;
      this.futureIndex = e.draggedContext.futureIndex;
      //console.log(e.draggedContext.uuid);
      return false; // disable sort
    },

    handleDragEnd: function () {
      /* hier loope ich den mutierten array durch und bilde
      einen neuen array aus uuid und prio.
      Dieses Array schicke ich dann via POST an die API */

      this.dragging = false;
      this.componentKey += 1;

      /* let idAndPrio = [];
      let newOrderedArray = this.data.fields;
      let prio = 1;

      newOrderedArray.forEach((item) => {
        const set = {
          uuid: item.id,
          prio: prio,
        };

        idAndPrio.push(set);
        ++prio;
      }); */
    },
  },

  mixins: [apiRequest],
};
</script>

<style scoped>
fieldset {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: white;
}
</style>
