<template>
  <v-card class="mb-4 grey lighten-5">
    <SliceHeader
      :edit="edit"
      :someSliceInEdit="someSliceInEdit"
      :componentName="element.componentName"
    />

    <v-card-text class="component">
      <div v-if="edit" class="component-edit">
        <v-form v-model="valid" class="d-block" id="form" ref="form">
          <fieldset class="px-5 py-5 mb-2">
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="tmplAction"
                  false-value="0"
                  true-value="1"
                  persistent-hint
                  hint="Die im Template definierte Action wird ausgeführt. z. B. wird das Formular geöffnet."
                  label="Template-Action"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="text"
                  label="Text auf Button"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  v-model="colorClass"
                  item-text="class"
                  item-value="class"
                  label="Farbe des Buttons*"
                  :items="colorClasses"
                  required
                ></v-select>
              </v-col>

              <template v-if="tmplAction === '0'">
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="targetUrl"
                    label="Ziel-URL"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="anchorTarget"
                    label="Ziel-Anker"
                    :persistent-hint="true"
                    hint="Die Klasse der Content-Section, zu der gescrollt werden soll."
                  ></v-text-field>
                </v-col>
              </template>
            </v-row>
          </fieldset>
        </v-form>
        <SectionSettings
          :data="element.data"
          @result="sectionData = $event"
        ></SectionSettings>
      </div>

      <div v-else class="component-preview">
        <v-btn
          :color="element.data.colorClass"
          :class="element.data.colorClass !== primary ? 'white--text' : ''"
        >
          {{ element.data.text }}
        </v-btn>
        <br /><br />
        <span v-if="tmplAction === '1'"> Template-Action wird ausgeführt </span>
        <span v-else-if="element.data.targetUrl">
          URL: {{ element.data.targetUrl }}
        </span>
        <span v-else-if="element.data.anchorTarget">
          Anker-Ziel: {{ element.data.anchorTarget }}
        </span>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-chip
        v-if="element.data.sliceIdent"
        x-small
        class="mt-4 float-right grey--text"
        color="grey lighten-2"
      >
        <v-icon x-small left>mdi-information</v-icon>Custom class: .slice_{{
          element.data.sliceIdent
        }}
      </v-chip>

      <v-spacer></v-spacer>

      <div v-if="edit" class="component-onedit-buttons">
        <EditButtons
          @emit="editbuttonAction"
          ShowSafeButton="true"
          ShowAbortButton="true"
        ></EditButtons>
      </div>
      <div v-else class="component-onpreview-buttons">
        <EditButtons
          v-if="!someSliceInEdit"
          @emit="editbuttonAction"
          ShowEditButton="true"
          ShowDeleteButton="true"
        ></EditButtons>

        <EditButtons
          v-else
          @emit="editbuttonAction"
          Disabled="true"
          ShowEditButton="true"
          ShowDeleteButton="true"
        ></EditButtons>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import SliceHeader from "@/components/pages/contentelements/components/ui/sliceHeader";
import EditButtons from "@/components/ui/EditButtons";
import SectionSettings from "@/components/pages/contentelements/components/SectionSettings";
export default {
  name: "Button",

  components: {
    SliceHeader,
    EditButtons,
    SectionSettings,
  },

  data() {
    return {
      lazy: true,
      valid: true,
      text: "",
      targetUrl: null,
      anchorTarget: null,
      tmplAction: false,
      edit: false,
      primary: null,
      colorClass: "primary",
      colorClasses: ["primary", "secondary", "green", "red"],
      sectionData: {},
    };
  },

  created() {
    this.text = this.$props.element.data.text ?? "";
    this.colorClass = this.$props.element.data.colorClass ?? "";
    this.targetUrl = this.$props.element.data.targetUrl ?? "";
    this.anchorTarget = this.$props.element.data.anchorTarget ?? "";
    this.tmplAction = this.$props.element.data.tmplAction ?? "0";
    this.$emit("sliceOnEdit", false); // emit to parent-component
  },

  methods: {
    /* Clicks werden via $emit action-parameter von der Kindkomponente <EditButtons></EditButtons> übergeben. 
    Der emit-Parameter (value, Object (action+itemId)) wird hier ausgewertet ...
    */

    editbuttonAction(value) {
      if (value.action === "editItem") {
        /* update items may could have changed 
        this avoids from keeping unsaved changes after abort */
        this.text = this.element.data.text;
        this.colorClass = this.element.data.colorClass;
        this.targetUrl = this.element.data.targetUrl;
        this.anchorTarget = this.element.data.anchorTarget;
        this.tmplAction = this.element.data.tmplAction;

        this.edit = true;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      } else if (value.action === "deleteItem") {
        let emitAction = {
          action: "delete",
          sliceId: this.sliceid,
          prio: this.prio,
        };

        this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
      } else if (value.action === "safeItem") {
        this.safeItem();
      } else if (value.action === "abortItem") {
        this.edit = false;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      }
    },

    safeItem() {
      const fields = {
        text: this.text ?? "",
        colorClass: this.colorClass ?? "",
        targetUrl: this.targetUrl ?? "",
        anchorTarget: this.anchorTarget ?? "",
        tmplAction: this.tmplAction ?? "0",
      };

      // add sectionData key/values to fields
      if (this.sectionData) {
        Object.keys(this.sectionData).forEach((key) => {
          fields[key] = this.sectionData[key];
        });
      }
      let emitAction = {
        action: "update",
        sliceId: this.sliceid,
        prio: this.prio,
        fields: fields,
      };

      this.edit = false;

      this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
      this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
    },
  },

  props: ["sliceid", "element", "someSliceInEdit", "prio"],
};
</script>

<style scoped>
fieldset {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
