<template>
  <v-row v-if="!fetchinData">
    <v-col v-for="item in data" :key="item.id" :cols="columnWidth">
      <v-card class="my-1">
        <v-img v-if="item.mediaUrl" height="250" :src="item.mediaUrl"></v-img>

        <v-card-text>
          <v-row>
            <v-col v-if="item.icon && item.icon !== 'null'" cols="12">
              <v-icon large color="primary">
                {{ item.icon }}
              </v-icon>
            </v-col>

            <v-col v-if="item.headline" cols="12" class="text-h6">
              {{ item.headline }}
            </v-col>

            <v-col v-if="item.textLong" cols="12">
              <vue-markdown
                class="caption"
                v-if="item.textLong"
                :source="item.textLong"
              ></vue-markdown>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import VueMarkdown from "vue-markdown/src/VueMarkdown";
export default {
  name: "ItemPreview",
  components: { VueMarkdown },
  props: ["componentId"],
  data() {
    return {
      data: [],
      columnsCount: 0,
      columnWidth: 12,
      fetchinData: false,
    };
  },
  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      // GET /componentsColumnsData/{componentID}
      this.fetchinData = true;
      let response = await this.getRequest(
        `/componentsColumnsData/${this.componentId}`
      );

      if (response.status === 200) {
        this.columnsCount = response.data.data.length;
        this.data = response.data.data;

        if (this.columnsCount === 1) {
          this.columnWidth = 12;
        } else if (this.columnsCount === 2) {
          this.columnWidth = 6;
        } else if (this.columnsCount === 3) {
          this.columnWidth = 4;
        } else {
          this.columnWidth = 4;
        }

        this.fetchinData = false;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
