<template>
  <div>
    <v-card class="mb-4 grey lighten-5">
      <SliceHeader
        :edit="edit"
        :someSliceInEdit="someSliceInEdit"
        :componentName="element.componentName"
      />

      <v-card-text class="component">
        <div v-if="edit" class="component-edit">
          <draggable
            v-model="selected"
            ghost-class="ghost"
            :move="checkMove"
            handle=".draghandle"
            @start="dragging = true"
            draggable=".is-draggable"
          >
            <template v-if="selected && selected.length > 0">
              <div
                v-for="(item, index) in selected"
                :key="item.id"
                class="is-draggable"
              >
                <Item
                  :items="items"
                  :item="item"
                  @remove="removeItem(index)"
                  :key="componentKey"
                />
              </div>
            </template>
          </draggable>

          <v-btn
            depressed
            class="my-4"
            color="primary"
            @click="addDialog = true"
          >
            Testimonial hinzufügen
          </v-btn>

          <SectionSettings
            :data="element.data"
            @result="sectionData = $event"
          ></SectionSettings>
        </div>

        <div v-else class="component-preview">
          <!-- Damit beim Abort nicht der mutierte Wert stehen bleibt, wird hier definitiv auf das Array verwiesen -->

          <ItemPreview
            v-for="item in selected"
            :key="item.id"
            :items="items"
            :item="item"
          />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-chip
          v-if="element.data.sliceIdent"
          x-small
          class="mt-4 float-right grey--text"
          color="grey lighten-2"
        >
          <v-icon x-small left>mdi-information</v-icon>Custom class: .slice_{{
            element.data.sliceIdent
          }}
        </v-chip>

        <v-spacer></v-spacer>

        <div v-if="edit" class="component-onedit-buttons">
          <EditButtons
            @emit="editbuttonAction"
            ShowSafeButton="true"
            ShowAbortButton="true"
          ></EditButtons>
        </div>
        <div v-else class="component-onpreview-buttons">
          <EditButtons
            v-if="!someSliceInEdit"
            @emit="editbuttonAction"
            ShowEditButton="true"
            ShowDeleteButton="true"
          ></EditButtons>

          <EditButtons
            v-else
            @emit="editbuttonAction"
            Disabled="true"
            ShowEditButton="true"
            ShowDeleteButton="true"
          ></EditButtons>
        </div>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="addDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      :key="componentKey"
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title>Wähle Testimonials</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="addDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <SelectTestimonial
            :selected="selected"
            :key="componentKey"
            @selection="selected = $event"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SliceHeader from "@/components/pages/contentelements/components/ui/sliceHeader";
import draggable from "vuedraggable";
import apiRequest from "@/mixins/apiRequest";
import EditButtons from "@/components/ui/EditButtons";
import SelectTestimonial from "@/components/pages/contentelements/components/testimonials/SelectTestimonial";
import Item from "@/components/pages/contentelements/components/testimonials/Item";
import ItemPreview from "@/components/pages/contentelements/components/testimonials/ItemPreview";
import SectionSettings from "@/components/pages/contentelements/components/SectionSettings";
export default {
  name: "Testimonials",

  components: {
    SliceHeader,
    EditButtons,
    SelectTestimonial,
    Item,
    ItemPreview,
    draggable,
    SectionSettings,
  },

  props: ["sliceid", "element", "someSliceInEdit", "prio"],

  data() {
    return {
      componentKey: 0,
      addDialog: false,
      selected: this.splitSelected(), // convert prop (string) to an array
      cleanSelection: this.splitSelected(),
      sliceId: this.sliceid,
      lazy: true,
      valid: true,
      items: [],
      text: "",
      headlineSize: "1",
      edit: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "personName" },
        { text: "Quote", value: "personQuote" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      sectionData: {},
    };
  },

  created() {
    this.$emit("sliceOnEdit", false); // emit to parent-component
    this.getData();
  },

  methods: {
    splitSelected() {
      if (this.element.data.foreign_ids) {
        return this.element.data.foreign_ids.split(",");
      }
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest("testimonials");
      this.fetchingData = false;

      if (response.status === 200) {
        this.items = response.data.data;
      }
    },

    removeItem(index) {
      this.selected.splice(index, 1);
      this.componentKey += 1;
    },

    /* Clicks werden via $emit action-parameter von der Kindkomponente <EditButtons></EditButtons> übergeben. 
    Der emit-Parameter (value, Object (action+itemId)) wird hier ausgewertet ...
    */

    editbuttonAction(value) {
      if (value.action === "editItem") {
        /* update items may could have changed 
        this avoids from keeping unsaved changes after abort */
        this.text = this.element.data.text;
        this.headlineSize = this.element.data.headlineSize;

        this.edit = true;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      } else if (value.action === "deleteItem") {
        let emitAction = {
          action: "delete",
          sliceId: this.sliceId,
          prio: this.prio,
        };

        this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
      } else if (value.action === "safeItem") {
        this.safeItem();
      } else if (value.action === "abortItem") {
        this.edit = false;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      }
    },

    safeItem() {
      /* emit only the fields that gets updated */

      const fields = {
        foreign_ids: this.selected ?? "",
      };

      // add sectionData key/values to fields
      if (this.sectionData) {
        Object.keys(this.sectionData).forEach((key) => {
          fields[key] = this.sectionData[key];
        });
      }

      let emitAction = {
        action: "update",
        sliceId: this.sliceId,
        prio: this.prio,
        fields: fields,
      };

      this.edit = false;

      this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
      this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
    },

    checkMove: function () {
      this.onDrag = true;
      //window.console.log("Future index: " + e.draggedContext.futureIndex);
    },

    handleMove(e) {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */
      this.movingIndex = e.draggedContext.index;
      this.futureIndex = e.draggedContext.futureIndex;
      //console.log(e.draggedContext.uuid);
      return false; // disable sort
    },

    handleDragEnd: function () {
      /* hier loope ich den mutierten array durch und bilde
      einen neuen array aus uuid und prio.
      Dieses Array schicke ich dann via POST an die API */

      this.dragging = false;
      this.componentKey += 1;

      /* let idAndPrio = [];
      let newOrderedArray = this.data.fields;
      let prio = 1;

      newOrderedArray.forEach((item) => {
        const set = {
          uuid: item.id,
          prio: prio,
        };

        idAndPrio.push(set);
        ++prio;
      }); */
    },
  },

  /* watch: {
    // whenever selected changes, this function will run
    // hier wird der selected array gesäubert ... es werden also nur die IDs übernommen
    selected: function() {
      this.cleanSelection = [];

      this.selected.forEach((item) => {
        this.cleanSelection.push({
          id: item,
        });
      });
    },
  }, */

  mixins: [apiRequest],
};
</script>
