<template>
  <v-card class="my-1">
    <v-card-text>
      <v-row class="align-center">
        <v-col cols="2">
          <MediaItemPreview
            v-if="personMediaUuid"
            :uuid="personMediaUuid"
            :showCaption="false"
            aspectRatio="1"
            width="100"
            class="my-1"
            :key="componentKey"
          />
        </v-col>

        <v-col cols="8">
          <span class="font-weight-bold">{{ personName }}</span
          ><br />
          <vue-markdown
            class="caption"
            v-if="personQuote"
            :source="personQuote"
          ></vue-markdown>
        </v-col>

        <v-col cols="1">
          <v-btn
            fab
            dark
            x-small
            elevation="0"
            color="red"
            @click="$emit('remove', true)"
          >
            <v-icon dark>
              mdi-trash-can
            </v-icon>
          </v-btn>
        </v-col>

        <v-col cols="1">
          <v-icon
            class="draghandle"
            large
            color="gray darken-2"
            style="cursor:pointer"
          >
            mdi-drag
          </v-icon>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import MediaItemPreview from "@/components/mediapool/MediaItemPreview";
import VueMarkdown from "vue-markdown/src/VueMarkdown";
export default {
  name: "Item",
  components: { MediaItemPreview, VueMarkdown },
  props: ["item", "items"],
  data() {
    return {
      componentKey: 0,
    };
  },
  created() {},

  methods: {},

  watch: {
    item: function() {
      this.componentKey += 1;
    },
  },

  computed: {
    personName() {
      let personName = "";

      this.items.forEach((person) => {
        if (person.id === this.item) {
          personName = person.personName;
        }
      });

      return personName;
    },

    personQuote() {
      let personQuote = "";

      this.items.forEach((person) => {
        if (person.id === this.item) {
          personQuote = person.personQuote;
        }
      });

      return personQuote;
    },

    personImageUrl() {
      let personImageUrl = "";

      this.items.forEach((person) => {
        if (person.id === this.item) {
          personImageUrl = person.personImageUrl;
        }
      });

      return personImageUrl;
    },

    personMediaUuid() {
      let personMediaUuid = "";

      this.items.forEach((person) => {
        if (person.id === this.item) {
          personMediaUuid = person.mediaUuid;
        }
      });

      return personMediaUuid;
    },
  },
};
</script>
