<template>
  <div>
    <h2>Template Settings (Spiele)</h2>

    <v-row>
      <v-col cols="12">
        <table class="default-table" v-if="!fetchingData">
          <thead></thead>
          <tbody>
            <tr>
              <td>
                Hero-Background-Image*<br />
                <span class="caption">
                  *) Das Bild wird auch zum Teilen verwendet
                </span>
              </td>
              <td>
                <Patcher
                  :itemValue="tmplImage1"
                  itemName="tmplImage1"
                  itemLabel=""
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement=""
                  inputType="media"
                  @emit="tmplImage1 = $event.value.tmplImage1"
                ></Patcher>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>

      <v-col cols="12">
        <v-form class="flex-grow-1 mt-4 d-block" id="form" ref="form">
          <v-row>
            <v-col cols="12">
              <h2>Formular</h2>
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="tmplFormId1"
                :items="forms"
                filled
                item-text="title"
                item-value="id"
                label="Formular"
                class="mx-2"
              ></v-select>
            </v-col>

            <template v-if="tmplFormId1 && tmplFormId1 !== 'null'">
              <v-col cols="12">
                <v-text-field
                  filled
                  class="flex-grow-1 mx-2"
                  v-model="tmplFormHeadline"
                  :counter="50"
                  label="Formular Überschrift"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  filled
                  class="flex-grow-1 mx-2"
                  hint="Formular Einleitungstext"
                  v-model="tmplFormText"
                  label="Formular Einleitungstext"
                ></v-textarea>
              </v-col>
            </template>

            <v-col cols="12">
              <h2>Template</h2>
            </v-col>

            <v-col cols="12">
              <v-text-field
                filled
                class="flex-grow-1 mx-2"
                v-model="tmplHeadline1"
                :counter="50"
                label="Hero Pre-Headline"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                filled
                class="flex-grow-1 mx-2"
                v-model="tmplHeadline2"
                :counter="50"
                label="Hero Headline"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                filled
                class="flex-grow-1 mx-2"
                v-model="tmplButtonText1"
                :counter="50"
                label="Button Text"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-alert border="top" color="red lighten-2" dark>
                <strong>Folgende Felder sind in Kürze obsolet.</strong
                ><br />Bitte trage die Werte
                <strong style="text-decoration: underline">ZUSÄTZLICH</strong>
                oben in die Felder "Formular Überschrift" und "Formular
                Einleitungstext" ein.
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-text-field
                filled
                class="flex-grow-1 mx-2"
                v-model="tmplHeadline3"
                :counter="50"
                label="Formular Headline"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                filled
                class="flex-grow-1 mx-2"
                hint="Formular Einleitungstext"
                v-model="tmplText2"
                label="Pre-Form Text"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-col>

      <v-col cols="12" class="text-right">
        <v-btn
          class="mx-1"
          fab
          x-small
          color="primary"
          elevation="0"
          @click="safeData()"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "Template4",

  components: {
    Patcher,
  },

  props: ["pageId", "pageData"],

  data() {
    return {
      file: null,
      filePatcherKey: 0,
      fetchingData: false,
      patchRoute: `pages/${this.pageId}`,
      forms: [],
      tmplFormId1: this.pageData.tmplFormId1 ?? null,
      tmplHeadline1: this.pageData.tmplHeadline1 ?? "",
      tmplHeadline2: this.pageData.tmplHeadline2 ?? "",
      tmplHeadline3: this.pageData.tmplHeadline3 ?? "",
      tmplButtonText1: this.pageData.tmplButtonText1 ?? "",
      tmplButtonText2: this.pageData.tmplButtonText2 ?? "",
      tmplButtonText3: this.pageData.tmplButtonText3 ?? "",
      tmplText1: this.pageData.tmplText1 ?? "",
      tmplText2: this.pageData.tmplText2 ?? "",
      tmplActive1: this.pageData.tmplActive1 ?? "0",
      tmplActive2: this.pageData.tmplActive2 ?? "0",
      tmplImage1: this.pageData.tmplImage1 ?? null,
      tmplImage2: this.pageData.tmplImage2 ?? null,
      tmplImage3: this.pageData.tmplImage3 ?? null,
      tmplImage1Url: this.pageData.tmplImage1Url ?? "",
      tmplImage2Url: this.pageData.tmplImage2Url ?? "",
      tmplImage3Url: this.pageData.tmplImage3Url ?? "",
      tmplFormHeadline: this.pageData.tmplFormHeadline ?? "",
      tmplFormText: this.pageData.tmplFormText ?? "",
    };
  },

  created: function () {
    this.getAvailableForms(); // call it immediatly
  },

  methods: {
    async getAvailableForms() {
      this.fetchingData = true;
      let response = await this.getRequest("forms");
      this.fetchingData = false;
      this.forms = response.data.data;
    },

    async safeData() {
      const params = new URLSearchParams();

      if (this.tmplHeadline1) {
        params.append("tmplHeadline1", this.tmplHeadline1);
      }

      if (this.tmplHeadline2) {
        params.append("tmplHeadline2", this.tmplHeadline2);
      }

      if (this.tmplHeadline3) {
        params.append("tmplHeadline3", this.tmplHeadline3);
      }

      if (this.tmplButtonText1) {
        params.append("tmplButtonText1", this.tmplButtonText1);
      }

      if (this.tmplButtonText2) {
        params.append("tmplButtonText2", this.tmplButtonText2);
      }

      if (this.tmplButtonText3) {
        params.append("tmplButtonText3", this.tmplButtonText3);
      }

      if (this.tmplText1) {
        params.append("tmplText1", this.tmplText1);
      }

      if (this.tmplText2) {
        params.append("tmplText2", this.tmplText2);
      }

      if (this.tmplActive1) {
        params.append("tmplActive1", this.tmplActive1);
      }

      if (this.tmplActive2) {
        params.append("tmplActive2", this.tmplActive2);
      }

      if (this.tmplFormId1) {
        params.append("tmplFormId1", this.tmplFormId1);
      }

      if (this.tmplFormHeadline) {
        params.append("tmplFormHeadline", this.tmplFormHeadline);
      }

      if (this.tmplFormText) {
        params.append("tmplFormText", this.tmplFormText);
      }

      this.fetchingData = true;
      let response = await this.putRequest(`pages/${this.pageId}`, params);
      this.fetchingData = false;

      if (response.status === 200) {
        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Gespeichert!";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // mutate
        this.pageData.tmplHeadline1 = response.data.data.tmplHeadline1;
        this.pageData.tmplHeadline2 = response.data.data.tmplHeadline2;
        this.pageData.tmplHeadline3 = response.data.data.tmplHeadline3;
        this.pageData.tmplButtonText1 = response.data.data.tmplButtonText1;
        this.pageData.tmplButtonText2 = response.data.data.tmplButtonText2;
        this.pageData.tmplButtonText3 = response.data.data.tmplButtonText3;
        this.pageData.tmplText1 = response.data.data.tmplText1;
        this.pageData.tmplText2 = response.data.data.tmplText2;
        this.pageData.tmplActive1 = response.data.data.tmplActive1;
        this.pageData.tmplActive2 = response.data.data.tmplActive2;
        this.pageData.tmplFormId1 = response.data.data.tmplFormId1;
        this.pageData.tmplFormHeadline = response.data.data.tmplFormHeadline;
        this.pageData.tmplFormText = response.data.data.tmplFormText;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
