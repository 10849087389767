<template>
  <v-card class="my-1">
    <v-card-text>
      <v-row class="align-center">
        <v-col cols="12">
          <span class="font-weight-bold">
            {{ gameName }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Item",
  components: {},
  props: ["item", "items"],
  data() {
    return {};
  },
  created() {},

  methods: {},

  computed: {
    gameName() {
      let gameName = "";

      this.items.forEach((item) => {
        if (item.id === this.item) {
          gameName = item.title;
        }
      });

      return gameName;
    },
  },
};
</script>
