<template>
  <v-card class="mb-4 grey lighten-5">
    <v-card-text class="component">
      <v-select
        v-model="selection"
        :items="items"
        @change="itemselected"
        item-text="title"
        item-value="id"
        label="Wähle ein Spiel"
        outlined
      ></v-select>
    </v-card-text>

    <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "SelectGame",

  components: {},

  props: ["selected"],

  data() {
    return {
      selection: this.selected,
      lazy: true,
      valid: true,
      items: [],
      genArray: [],
      edit: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "title" },
      ],
    };
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest("debugging_game");
      this.fetchingData = false;
      if (response.status === 200) {
        this.items = response.data.data;
      }
    },

    itemselected() {
      this.$emit("selection", this.selection);
    },
  },

  mixins: [apiRequest],
};
</script>
