<template>
  <v-card class="my-1">
    <v-card-text>
      <v-row class="align-center">
        <v-col cols="2">
          <MediaItemPreview
            v-if="personMediaUuid"
            :uuid="personMediaUuid"
            :showCaption="false"
            aspectRatio="1"
            width="100"
            class="my-1"
          />
        </v-col>

        <v-col cols="10">
          <span class="font-weight-bold">{{ obaName }}</span
          ><br />
          <vue-markdown
            class="caption"
            v-if="infoText"
            :source="infoText"
          ></vue-markdown>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import MediaItemPreview from "@/components/mediapool/MediaItemPreview";
import VueMarkdown from "vue-markdown/src/VueMarkdown";
export default {
  name: "Item",
  components: { MediaItemPreview, VueMarkdown },
  props: ["item", "items"],
  data() {
    return {};
  },
  created() {},

  methods: {},

  computed: {
    obaName() {
      let obaName = "";

      this.items.forEach((item) => {
        if (item.id === this.item) {
          obaName = item.name;
        }
      });

      return obaName;
    },

    obaId() {
      let obaId = "";

      this.items.forEach((item) => {
        if (item.id === this.item) {
          obaId = item.id;
        }
      });

      return obaId;
    },

    infoText() {
      let obaDesc = "";

      this.items.forEach((item) => {
        if (item.id === this.item) {
          obaDesc = item.infoText;
        }
      });

      return obaDesc;
    },

    personMediaUuid() {
      let personMediaUuid = "";

      this.items.forEach((person) => {
        if (person.id === this.item) {
          personMediaUuid = person.mediaUuid;
        }
      });

      return personMediaUuid;
    },
  },
};
</script>
