<template>
  <v-card-title>
    <div class="overline mb-4">
      <v-icon
        v-if="!edit && !someSliceInEdit"
        class="draghandle"
        style="cursor:grab"
        left
      >
        mdi-drag
      </v-icon>
      <v-icon v-else left style="opacity:.2;cursor:not-allowed;">
        mdi-drag
      </v-icon>
      Komponente: {{ componentName }}
    </div>
  </v-card-title>
</template>

<script>
export default {
  name: "sliceHeader",
  components: {},
  props: ["componentName", "someSliceInEdit", "edit"],
  data() {
    return {};
  },
  created() {},
  methods: {},
  computed: {},
};
</script>
