<template>
  <v-card class="mb-4 grey lighten-5">
    <SliceHeader
      :edit="edit"
      :someSliceInEdit="someSliceInEdit"
      :componentName="element.componentName"
    />

    <v-card-text class="component">
      <div v-if="edit" class="component-edit">
        <v-form v-model="valid" class="d-block" id="form" ref="form">
          <fieldset class="px-5 py-5 mb-2">
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="useButton"
                  false-value="0"
                  true-value="1"
                  persistent-hint
                  hint="Soll ein Button verwendet werden?"
                  label="Button verwenden?"
                ></v-checkbox>
              </v-col>
              <v-col v-if="useButton" cols="12" md="6">
                <v-text-field
                  v-model="buttonText"
                  label="Text auf Button"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-checkbox
                  v-model="useSoldierNumber"
                  false-value="0"
                  true-value="1"
                  persistent-hint
                  hint="Soldaten-Plugin verwendet?"
                  label="Soldaten-Plugin"
                ></v-checkbox>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  class="flex-grow-1 mx-2"
                  v-model="title"
                  :counter="50"
                  label="Überschrift"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-md-editor
                  v-model="text"
                  height="400px"
                  left-toolbar="h bold italic | ul ol | emoji"
                  right-toolbar="preview fullscreen"
                  :disabled-menus="['h/h1']"
                  mode="edit"
                ></v-md-editor>
              </v-col>

              <v-col cols="12">
                <AddMediaFromMediapool
                  :mediaUuid="mediaUuid"
                  @mediaAdded="mediaUuid = $event"
                />
              </v-col>
            </v-row>
          </fieldset>
        </v-form>
      </div>

      <div v-else class="component-preview">
        <v-img
          v-if="element.data.mediaUrl"
          max-width="250"
          :src="element.data.mediaUrl"
        ></v-img>
        <!-- 
            In der Anleitung so: <vue-markdown>mein text</vue-markdown>
            Muss ich so machen, da text sonst nicht geupdated wird: <vue-markdown :source="element.data.text"></vue-markdown>
            -> https://github.com/miaolz123/vue-markdown
            VueMarkdown has a default slot which is used to write the markdown source.
            TIP: The default slot only renders once at the beginning, and it will overwrite the prop of source!
          -->
        <vue-markdown
          v-if="element.data.text"
          :source="element.data.text"
        ></vue-markdown>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-chip
        v-if="element.data.sliceIdent"
        x-small
        class="mt-4 float-right grey--text"
        color="grey lighten-2"
      >
        <v-icon x-small left>mdi-information</v-icon>Custom class: .slice_{{
          element.data.sliceIdent
        }}
      </v-chip>

      <v-spacer></v-spacer>

      <div v-if="edit" class="component-onedit-buttons">
        <EditButtons
          @emit="editbuttonAction"
          ShowSafeButton="true"
          ShowAbortButton="true"
        ></EditButtons>
      </div>
      <div v-else class="component-onpreview-buttons">
        <EditButtons
          v-if="!someSliceInEdit"
          @emit="editbuttonAction"
          ShowEditButton="true"
          ShowDeleteButton="true"
        ></EditButtons>

        <EditButtons
          v-else
          @emit="editbuttonAction"
          Disabled="true"
          ShowEditButton="true"
          ShowDeleteButton="true"
        ></EditButtons>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import SliceHeader from "@/components/pages/contentelements/components/ui/sliceHeader";
import EditButtons from "@/components/ui/EditButtons";
import VueMarkdown from "vue-markdown/src/VueMarkdown"; // neuer Weg (s. oben)
import AddMediaFromMediapool from "@/components/ui/AddMediaFromMediapool";
export default {
  name: "Prompt",

  components: {
    SliceHeader,
    EditButtons,
    VueMarkdown,
    AddMediaFromMediapool,
  },

  data() {
    return {
      lazy: true,
      valid: true,
      text: "",
      targetUrl: null,
      anchorTarget: null,
      tmplAction: false,
      edit: false,
      configs: {
        status: false,
        spellChecker: false,
        sanitize: true,
        toolbar: [
          "bold",
          "italic",
          "heading-1",
          "heading-2",
          "heading-3",
          "unordered-list",
          "ordered-list",
          "link",
          "preview",
        ],
      },
    };
  },

  created() {
    this.text = this.$props.element.data.text ?? "";
    this.title = this.$props.element.data.title ?? "";
    this.useButton = this.$props.element.data.useButton ?? "0";
    this.mediaUuid = this.$props.element.data.mediaUuid ?? "";
    this.buttonText = this.$props.element.data.buttonText ?? "";
    this.useSoldierNumber = this.$props.element.data.useSoldierNumber ?? "0";
    this.$emit("sliceOnEdit", false); // emit to parent-component
  },

  methods: {
    /* Clicks werden via $emit action-parameter von der Kindkomponente <EditButtons></EditButtons> übergeben. 
    Der emit-Parameter (value, Object (action+itemId)) wird hier ausgewertet ...
    */

    editbuttonAction(value) {
      if (value.action === "editItem") {
        /* update items may could have changed 
        this avoids from keeping unsaved changes after abort */
        this.text = this.element.data.text ?? "";
        this.title = this.element.data.title ?? "";
        this.useButton = this.element.data.useButton ?? "0";
        this.mediaUuid = this.element.data.mediaUuid ?? "";
        this.buttonText = this.element.data.buttonText ?? "";
        this.useSoldierNumber = this.element.data.useSoldierNumber ?? "0";

        this.edit = true;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      } else if (value.action === "deleteItem") {
        let emitAction = {
          action: "delete",
          sliceId: this.sliceid,
          prio: this.prio,
        };

        this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
      } else if (value.action === "safeItem") {
        this.safeItem();
      } else if (value.action === "abortItem") {
        this.edit = false;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      }
    },

    safeItem() {
      /* emit only the fields that gets updated */
      let emitAction = {
        action: "update",
        sliceId: this.sliceid,
        prio: this.prio,
        fields: {
          text: this.text ?? "",
          title: this.title ?? "",
          useButton: this.useButton ?? "0",
          mediaUuid: this.mediaUuid ?? "",
          buttonText: this.buttonText ?? "",
          useSoldierNumber: this.useSoldierNumber ?? "0",
        },
      };

      this.edit = false;

      this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
      this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
    },
  },

  props: ["sliceid", "element", "someSliceInEdit", "prio"],
};
</script>

<style scoped>
fieldset {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
