 <template>

    <v-card class="mb-4 grey lighten-5" style="opacity:.5">

      <v-card-title>
        <div class="overline mb-4">
           <v-progress-circular indeterminate color="primary" :size="15" :width="2"></v-progress-circular> {{element.name}} wird erstellt ...
        </div>
      </v-card-title>

      <v-card-text class="component">

      <div class="component-preview">
        Bitte warten ...
      </div>

      </v-card-text>
      
      <v-card-actions></v-card-actions>

    </v-card>

</template>



<script>
export default {
  name: 'PlaceholderComponent',
  props: [
    'element'
  ]
}
</script>

