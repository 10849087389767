<template>
  <div>
    <v-card class="mb-4 grey lighten-5">
      <SliceHeader
        :edit="edit"
        :someSliceInEdit="someSliceInEdit"
        :componentName="element.componentName"
      />

      <v-card-text class="component">
        <div v-if="edit" class="component-edit">
          <div v-for="(item, index) in selected" :key="item.id">
            <Item
              :items="items"
              :item="item"
              @remove="removeItem(index)"
              :key="componentKey"
            />
          </div>

          <v-btn
            v-if="companies_id && !fetchingData"
            depressed
            class="my-4"
            color="primary"
            @click="addDialog = true"
          >
            Shop-Kategorie hinzufügen
          </v-btn>

          <span v-else>
            <p>
              <strong class="red--text">
                Es ist noch nicht möglich Shop-Kategorien hinzuzufügen.
              </strong>
            </p>
            <p>
              Erst, wenn das Landingpage-Template einer Kampagne zugewiesen
              wurde, können Shop-Kategorien hinzugefügt werden, da erst dann
              eine Firmenzuweisung möglich ist.
            </p>
          </span>

          <SectionSettings
            :data="element.data"
            @result="sectionData = $event"
          ></SectionSettings>
        </div>

        <div v-else class="component-preview">
          <!-- Damit beim Abort nicht der mutierte Wert stehen bleibt, wird hier definitiv auf das Array verwiesen -->
          <ItemPreview
            v-for="item in selected"
            :key="item.id"
            :item="item"
            :items="items"
          />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-chip
          v-if="element.data.sliceIdent"
          x-small
          class="mt-4 float-right grey--text"
          color="grey lighten-2"
        >
          <v-icon x-small left>mdi-information</v-icon>Custom class: .slice_{{
            element.data.sliceIdent
          }}
        </v-chip>

        <v-spacer></v-spacer>

        <div v-if="edit" class="component-onedit-buttons">
          <EditButtons
            @emit="editbuttonAction"
            ShowSafeButton="true"
            ShowAbortButton="true"
          ></EditButtons>
        </div>
        <div v-else class="component-onpreview-buttons">
          <EditButtons
            v-if="!someSliceInEdit"
            @emit="editbuttonAction"
            ShowEditButton="true"
            ShowDeleteButton="true"
          ></EditButtons>

          <EditButtons
            v-else
            @emit="editbuttonAction"
            Disabled="true"
            ShowEditButton="true"
            ShowDeleteButton="true"
          ></EditButtons>
        </div>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="addDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      :key="componentKey"
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title>Wähle Shop-Kategorien</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="addDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <SelectCategories
            :items="items"
            :selected="selected"
            :key="componentKey"
            @selection="
              selected = $event;
              addDialog = false;
            "
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import SliceHeader from "@/components/pages/contentelements/components/ui/sliceHeader";
import EditButtons from "@/components/ui/EditButtons";
import Item from "@/components/pages/contentelements/components/shop/Item";
import ItemPreview from "@/components/pages/contentelements/components/shop/ItemPreview";
import SelectCategories from "@/components/pages/contentelements/components/shop/SelectCategories";
import SectionSettings from "@/components/pages/contentelements/components/SectionSettings";
export default {
  name: "Shop",

  components: {
    SliceHeader,
    EditButtons,
    Item,
    ItemPreview,
    SelectCategories,
    SectionSettings,
  },

  // * Da die Komponente Company-Basiert ist, werden hier die companies_id und campaigns_id übergeben
  props: [
    "sliceid",
    "element",
    "someSliceInEdit",
    "prio",
    "companies_id",
    "campaigns_id",
  ],

  data() {
    return {
      componentKey: 0,
      addDialog: false,
      selected: this.splitSelected(), // convert prop (string) to an array
      cleanSelection: this.splitSelected(),
      lazy: true,
      valid: true,
      title: "",
      titleSize: "1",
      edit: false,
      titleSizes: ["1", "2", "3", "4", "5"],
      items: [],
      shops: [],
      sectionData: {},
    };
  },

  created() {
    this.$emit("sliceOnEdit", false); // emit to parent-component

    if (this.companies_id) {
      this.getData();
    }
  },

  methods: {
    async getAllCategoriesOfAllShops() {
      // loop though this.shops and get all categories by route /addons/shop/{shopId}/categories.
      // build an array of objects (shopId, shopTitle, categories) and set it to this.items
      for (let i = 0; i < this.shops.length; i++) {
        let shop = this.shops[i];

        let response = await this.getRequest(
          `addons/shop/${shop.id}/categories`
        );
        if (response.status === 200) {
          let categories = response.data.data;

          // loop through categories and build an array of category titles

          for (let i = 0; i < categories.length; i++) {
            let category = categories[i];
            let item = {
              shopId: shop.id,
              shopTitle: shop.title,
              id: category.id,
              title: category.title,
            };
            this.items.push(item);
          }
        }
      }
    },

    async getData() {
      // get shops of company
      this.fetchingData = true;
      let response = await this.getRequest(
        `addons/shop?filter[companies_id]=${this.companies_id}`
      );
      if (response.status === 200) {
        this.shops = response.data.data;
        this.getAllCategoriesOfAllShops();
      }
      this.fetchingData = false;
    },

    splitSelected() {
      if (this.element.data.foreign_ids) {
        return this.element.data.foreign_ids.split(",");
      } else {
        return [];
      }
    },

    removeItem(index) {
      this.selected.splice(index, 1);
      this.componentKey += 1;
    },

    /* Clicks werden via $emit action-parameter von der Kindkomponente <EditButtons></EditButtons> übergeben. 
    Der emit-Parameter (value, Object (action+itemId)) wird hier ausgewertet ...
    */

    editbuttonAction(value) {
      if (value.action === "editItem") {
        /* update items may could have changed 
        this avoids from keeping unsaved changes after abort */
        this.title = this.element.data.title;
        this.titleSize = this.element.data.titleSize;

        this.edit = true;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      } else if (value.action === "deleteItem") {
        let emitAction = {
          action: "delete",
          sliceId: this.sliceid,
          prio: this.prio,
        };

        this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
      } else if (value.action === "safeItem") {
        this.safeItem();
      } else if (value.action === "abortItem") {
        this.edit = false;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      }
    },

    safeItem() {
      const fields = {
        foreign_ids: this.selected,
      };

      // add sectionData key/values to fields
      if (this.sectionData) {
        Object.keys(this.sectionData).forEach((key) => {
          fields[key] = this.sectionData[key];
        });
      }

      let emitAction = {
        action: "update",
        sliceId: this.sliceid,
        prio: this.prio,
        fields: fields,
      };

      this.edit = false;

      this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
      this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
    },
  },

  mixins: [apiRequest],
};
</script>

<style scoped>
fieldset {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
