<template>
  <v-card class="my-1">
    <v-card-text>
      <v-row class="align-center">
        <v-col cols="2">
          <MediaItemPreview
            v-if="personMediaUuid"
            :uuid="personMediaUuid"
            :showCaption="false"
            aspectRatio="1"
            width="100"
            class="my-1"
          />
        </v-col>

        <v-col cols="10">
          <span class="font-weight-bold">{{ personName }}</span
          ><br />
          <vue-markdown
            class="caption"
            v-if="personQuote"
            :source="personQuote"
          ></vue-markdown>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import MediaItemPreview from "@/components/mediapool/MediaItemPreview";
import VueMarkdown from "vue-markdown/src/VueMarkdown";
export default {
  name: "Item",
  components: { MediaItemPreview, VueMarkdown },
  props: ["item", "items"],
  data() {
    return {};
  },
  created() {},

  methods: {},

  computed: {
    personName() {
      let personName = "";

      this.items.forEach((person) => {
        if (person.id === this.item) {
          personName = person.personName;
        }
      });

      return personName;
    },

    personQuote() {
      let personQuote = "";

      this.items.forEach((person) => {
        if (person.id === this.item) {
          personQuote = person.personQuote;
        }
      });

      return personQuote;
    },

    personImageUrl() {
      let personImageUrl = "";

      this.items.forEach((person) => {
        if (person.id === this.item) {
          personImageUrl = person.personImageUrl;
        }
      });

      return personImageUrl;
    },

    personMediaUuid() {
      let personMediaUuid = "";

      this.items.forEach((person) => {
        if (person.id === this.item) {
          personMediaUuid = person.mediaUuid;
        }
      });

      return personMediaUuid;
    },
  },
};
</script>
