<template>
  <div>
    <v-card class="mx-auto">
      <v-toolbar color="dark" dark>
        <v-icon left>mdi-database</v-icon>
        <v-toolbar-title>Meta</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-form class="flex-grow-1 mt-4 d-block" id="form" ref="form">
              <div class="d-flex mb-1">
                <v-text-field
                  filled
                  class="flex-grow-1 mx-2"
                  v-model="title"
                  :counter="50"
                  label="Seitentitel"
                  required
                ></v-text-field>
              </div>

              <div class="d-flex mb-2">
                <v-textarea
                  filled
                  class="flex-grow-1 mx-2"
                  hint="Kurzbeschreibung der Seite"
                  v-model="seoDescription"
                  :counter="160"
                  label="Kurzbeschreibung der Seite"
                ></v-textarea>
              </div>

              <div class="d-flex mb-1">
                <v-checkbox
                  class="flex-grow-1 mx-2"
                  v-model="isLive"
                  label="Seite ist live"
                ></v-checkbox>
              </div>
            </v-form>
          </v-col>

          <v-col cols="12" class="text-right">
            <v-btn
              class="mx-1"
              fab
              x-small
              color="primary"
              elevation="0"
              @click="safeData()"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "Pagemeta",

  components: {},

  data() {
    return {
      title: this.data.title,
      seoDescription: this.data.seoDescription,
      isLive: this.data.isLive,
    };
  },

  props: ["data", "pageId"],

  methods: {
    async safeData() {
      const params = new URLSearchParams();
      params.append("title", this.title);
      params.append("seoDescription", this.seoDescription);
      params.append("isLive", this.isLive);

      this.preloader = true;
      let response = await this.putRequest(`pages/${this.pageId}`, params);
      this.preloader = false;

      if (response.status === 200) {
        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Gespeichert!";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // mutate
        this.data.title = response.data.data.title;
        this.data.seoDescription = response.data.data.seoDescription;
        this.data.isLive = response.data.data.isLive;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
