<script>
import ColorPicker from "@/components/ui/ColorPicker";
import AddMediaFromMediapool from "@/components/ui/AddMediaFromMediapool";
export default {
  name: "SectionSettings",

  components: {
    ColorPicker,
    AddMediaFromMediapool,
  },

  props: ["data"],

  data() {
    return {
      dialog: false,
      sectionBackgroundColor: this.data.sectionBackgroundColor,
      sectionFontColor: this.data.sectionFontColor,
      bg_mediaUuid: this.data.bg_mediaUuid,
    };
  },

  mounted() {
    // sofort, da sectionData im Parent gefüllt sein muss, damit diese nicht leer sind
    this.emitData();
  },

  methods: {
    emitData() {
      const result = {
        sectionBackgroundColor: this.sectionBackgroundColor ?? "",
        sectionFontColor: this.sectionFontColor ?? "",
        bg_mediaUuid: this.bg_mediaUuid ?? "",
      };
      this.$emit("result", result);
    },
  },
};
</script>

<template>
  <v-row no-gutters>
    <v-col cols="12">
      <h2 class="my-4">Section-Settings</h2>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="sectionBackgroundColor"
            filled
            label="Hintergrundfarbe"
            disabled
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <ColorPicker
            :color="sectionBackgroundColor"
            :companyColors="true"
            @changed="
              sectionBackgroundColor = $event;
              emitData();
            "
          ></ColorPicker>

          <v-btn
            class="mx-2"
            fab
            dark
            x-small
            color="red"
            @click="
              sectionBackgroundColor = null;
              emitData();
            "
          >
            <v-icon dark> mdi-minus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="sectionFontColor"
            filled
            label="Schriftfarbe"
            disabled
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <ColorPicker
            :color="sectionFontColor"
            :companyColors="true"
            @changed="
              sectionFontColor = $event;
              emitData();
            "
          ></ColorPicker>

          <v-btn
            class="mx-2"
            fab
            dark
            x-small
            color="red"
            @click="
              sectionFontColor = null;
              emitData();
            "
          >
            <v-icon dark> mdi-minus </v-icon>
          </v-btn>
        </v-col>

        <v-col cols="12" md="6">
          <h2 class="mb-4">Hintergrundbild</h2>
          <AddMediaFromMediapool
            :mediaUuid="bg_mediaUuid"
            @mediaAdded="
              bg_mediaUuid = $event;
              emitData();
            "
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
