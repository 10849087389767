<template>
  <div>
    <v-card class="mx-auto" :loading="preloader">
      <v-toolbar color="dark" dark>
        <v-icon left>mdi-brush</v-icon>
        <v-toolbar-title>Template</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-select
          v-model="tmplId"
          :items="items"
          filled
          item-text="title"
          item-value="id"
          label="Template"
          @change="safeData()"
          class="mx-2"
        ></v-select>

        <component
          v-if="tmplId"
          :pageId="pageId"
          :pageData="pageData"
          :is="'Template' + tmplId"
          :key="componentKey"
        ></component>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
/* Die Optionen zu den Templates werden als Komponenten geladen.
Die Namenskonvention basiert auf der ID des Templates Template[ID].vue */
import apiRequest from "@/mixins/apiRequest";
import Template1 from "@/components/pages/templatesettings/Template1.vue";
import Template2 from "@/components/pages/templatesettings/Template2.vue";
import Template3 from "@/components/pages/templatesettings/Template3.vue";
import Template4 from "@/components/pages/templatesettings/Template4.vue";
import Template5 from "@/components/pages/templatesettings/Template5.vue";
export default {
  components: {
    Template1,
    Template2,
    Template3,
    Template4,
    Template5,
  },

  props: ["pageId", "pageData", "templates_id"],

  data() {
    return {
      preloader: false,
      tmplId: this.templates_id,
      componentKey: 0,
      items: [],
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      this.preloader = true;
      let response = await this.getRequest("pages/templates"); // await data from mixin
      this.preloader = false;

      if (response.status === 200) {
        this.items = response.data.data;
      }

      this.preloader = false;
    },

    async safeData() {
      const params = new URLSearchParams();
      params.append("templates_id", this.tmplId);

      this.preloader = true;
      let response = await this.putRequest(`pages/${this.pageId}`, params);

      if (response.status === 200) {
        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Gespeichert!";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
      this.componentKey += 1;
      this.preloader = false;
    },
  },

  mixins: [apiRequest],
};
</script>
