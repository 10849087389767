<template>
  <v-card class="mb-4 grey lighten-5">
    <v-card-text class="component">
      <v-data-table
        v-model="selection"
        :headers="headers"
        :items="items"
        item-key="id"
        selectable-key="isActive"
        @item-selected="itemselected"
        show-select
        dense
      >
      </v-data-table>
    </v-card-text>

    <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "SelectTestimonial",

  components: {},

  props: ["selected"],

  data() {
    return {
      selection: this.selected,
      lazy: true,
      valid: true,
      items: [],
      genArray: [],
      edit: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "personName" },
        { text: "Quote", value: "personQuote" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  created() {
    // ich muss das Array selection aufbereiten

    if (this.selection && this.selection !== "") {
      this.selection.forEach((item) => {
        const el = { id: item };
        this.genArray.push(el);
      });

      this.selection = this.genArray;
    }

    this.getData();
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest("testimonials");
      this.fetchingData = false;

      if (response.status === 200) {
        this.items = response.data.data;
      }
    },

    itemselected() {
      /* warum auch immer wird getriggert, bevor usersCompanies mutiert wurde.
      Deswegen warte ich 100ms */
      const that = this;
      setTimeout(function() {
        // ich muss es in ein anderes format bringen
        /* 
        [ { "id": "1" }, { "id": "2" }, { "id": "3" }, { "id": "4" } ]

        [{
          id: 1
        }] */

        let newFormat = [];

        that.selection.forEach((item) => {
          newFormat.push(item.id);
        });

        console.log(newFormat);
        that.$emit("selection", newFormat);
      }, 100);
    },
  },

  mixins: [apiRequest],
};
</script>
