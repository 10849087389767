<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-form v-model="valid" class="mt-4">
          <v-row>
            <v-col cols="3">
              <v-text-field
                outlined
                v-model="icon"
                label="icon"
                hide-details
              ></v-text-field>
              <span v-html="iconHint" class="caption"></span>
            </v-col>

            <v-col cols="9">
              <v-text-field
                outlined
                v-model="headline"
                :rules="requiredRules"
                label="Headline"
                required
              ></v-text-field>
            </v-col>

            <v-col>
              <h2 class="mb-4">Bild</h2>
              <AddMediaFromMediapool
                :mediaUuid="mediaUuid"
                @mediaAdded="mediaUuid = $event"
              />
            </v-col>

            <v-col cols="12">
              <h2 class="mb-4">Text</h2>
              <v-md-editor
                v-model="textLong"
                height="200px"
                left-toolbar="h bold italic | ul ol | emoji"
                right-toolbar="preview fullscreen"
                :disabled-menus="['h/h1']"
                mode="edit"
              ></v-md-editor>
            </v-col>

            <v-col cols="12">
              <h2>Text Zusatz</h2>
              <p>
                Erreichbar über einen "Mehr erfahren" Button. Der Inhalt öffnet
                sich in einem Dialog-Fenster.
              </p>
              <v-md-editor
                v-model="textAdditional"
                height="400px"
                left-toolbar="h bold italic | ul ol | emoji"
                right-toolbar="preview fullscreen"
                :disabled-menus="['h/h1']"
                mode="edit"
              ></v-md-editor>
            </v-col>
          </v-row>
        </v-form>
      </v-col>

      <v-col cols="12">
        <v-btn v-if="valid" color="green" dark @click="safeData">{{
          $t("controles.Safe")
        }}</v-btn>
        <v-btn v-else disabled>{{ $t("controles.Safe") }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import AddMediaFromMediapool from "@/components/ui/AddMediaFromMediapool";

export default {
  name: "EditColumn",

  props: {
    data: { required: true },
  },

  components: {
    AddMediaFromMediapool,
  },

  data() {
    return {
      textLong: this.data.textLong ?? "",
      textAdditional: this.data.textAdditional ?? "",
      mediaUuid: this.data.mediaUuid ?? "",
      headline: this.data.headline ?? "",
      icon: this.data.icon && this.data.icon !== "null" ? this.data.icon : "",
      valid: false,
      pageId: this.$route.params.id,
      requiredRules: [(v) => !!v || "Field is required"],
      componentKey: 0,
      addMediaDialog: false,
      loading: false,
      uploading: false,
      selectedItemType: null,
      selectedItemExt: null,
      itemsSelected: [],
      itemSelectedUuid: null,
      configs: {
        status: false,
        spellChecker: false,
        sanitize: true,
        toolbar: [
          "bold",
          "italic",
          "heading-1",
          "heading-2",
          "heading-3",
          "unordered-list",
          "ordered-list",
          "link",
          "preview",
        ],
      },
      iconHint:
        "<a href='https://pictogrammers.github.io/@mdi/font/5.3.45/' target='_blank'>Verfügbaren Icons</a>",
    };
  },

  created: function () {
    if (!this.textLong) {
      this.textLong = "";
    }
    if (!this.textAdditional) {
      this.textAdditional = "";
    }
  },

  methods: {
    async safeData() {
      const params = new URLSearchParams();
      params.append("textLong", this.textLong ?? "");
      params.append("textAdditional", this.textAdditional ?? "");
      params.append("mediaUuid", this.mediaUuid ?? "");
      params.append("headline", this.headline ?? "");
      params.append("icon", this.icon ?? "");

      this.preloader = true;
      let response = await this.patchRequest(
        `componentsColumnsData/${this.data.columns_id}/${this.data.id}`,
        params
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemUpdatedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        this.textLong = response.data.data.textLong;
        this.textAdditional = response.data.data.textAdditional;
        this.mediaUuid = response.data.data.mediaUuid;
        this.headline = response.data.data.headline;
        this.icon = response.data.data.icon;

        this.data.textLong = this.textLong;
        this.data.textAdditional = this.textAdditional;
        this.data.mediaUuid = this.mediaUuid;
        this.data.headline = this.headline;
        this.data.icon = this.icon;

        this.$emit("updated", {
          textLong: this.textLong,
          textAdditional: this.textAdditional,
          mediaUuid: this.mediaUuid,
          headline: this.headline,
          icon: this.icon,
        });
      }
    },

    itemSelected(data) {
      // SINGLE-item selected in mediapool
      this.mediaUuid = data.uuid;
      this.selectedItemType = data.type;
      this.selectedItemExt = data.extension;
      this.addMediaDialog = false;
      this.componentKey += 1;
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
