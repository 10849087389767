<template>
  <v-card class="mb-4 grey lighten-5">
    <SliceHeader
      :edit="edit"
      :someSliceInEdit="someSliceInEdit"
      :componentName="element.componentName"
    />

    <v-card-text class="component">
      <div v-if="edit" class="component-edit">
        <ColumnsDetails :componentId="element.componentId" />
        <SectionSettings
          :data="element.data"
          @result="sectionData = $event"
        ></SectionSettings>
      </div>

      <div v-else class="component-preview">
        <ItemPreview :componentId="element.componentId" />
      </div>
    </v-card-text>

    <v-card-actions>
      <v-chip
        v-if="element.data.sliceIdent"
        x-small
        class="mt-4 float-right grey--text"
        color="grey lighten-2"
      >
        <v-icon x-small left>mdi-information</v-icon>Custom class: .slice_{{
          element.data.sliceIdent
        }}
      </v-chip>

      <v-spacer></v-spacer>

      <div v-if="edit" class="component-onedit-buttons">
        <EditButtons
          @emit="editbuttonAction"
          ShowSafeButton="true"
          ShowAbortButton="true"
        ></EditButtons>
      </div>
      <div v-else class="component-onpreview-buttons">
        <EditButtons
          v-if="!someSliceInEdit"
          @emit="editbuttonAction"
          ShowEditButton="true"
          ShowDeleteButton="true"
        ></EditButtons>

        <EditButtons
          v-else
          @emit="editbuttonAction"
          Disabled="true"
          ShowEditButton="true"
          ShowDeleteButton="true"
        ></EditButtons>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import SliceHeader from "@/components/pages/contentelements/components/ui/sliceHeader";
import EditButtons from "@/components/ui/EditButtons";
import ColumnsDetails from "@/components/pages/contentelements/components/columns/ColumnsDetails";
import ItemPreview from "@/components/pages/contentelements/components/columns/ItemPreview";
import SectionSettings from "@/components/pages/contentelements/components/SectionSettings";
export default {
  name: "Headline",

  components: {
    SliceHeader,
    EditButtons,
    ColumnsDetails,
    ItemPreview,
    SectionSettings,
  },

  props: ["sliceid", "element", "someSliceInEdit", "prio"],

  data() {
    return {
      lazy: true,
      valid: true,
      title: "",
      titleSize: "1",
      edit: false,
      titleSizes: ["1", "2", "3", "4", "5"],
      columnSizes: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      sectionData: {},
    };
  },

  created() {
    this.title = this.$props.element.data.title ?? "";
    this.titleSize = this.$props.element.data.titleSize ?? "1";
    this.columnSize = this.$props.element.data.columnSize ?? "12";
    this.$emit("sliceOnEdit", false); // emit to parent-component
  },

  methods: {
    /* Clicks werden via $emit action-parameter von der Kindkomponente <EditButtons></EditButtons> übergeben. 
    Der emit-Parameter (value, Object (action+itemId)) wird hier ausgewertet ...
    */

    editbuttonAction(value) {
      if (value.action === "editItem") {
        /* update items may could have changed 
        this avoids from keeping unsaved changes after abort */
        this.title = this.element.data.title;
        this.titleSize = this.element.data.titleSize;
        this.columnSize = this.element.data.columnSize;

        this.edit = true;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      } else if (value.action === "deleteItem") {
        let emitAction = {
          action: "delete",
          sliceId: this.sliceid,
          prio: this.prio,
        };

        this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
      } else if (value.action === "safeItem") {
        this.safeItem();
      } else if (value.action === "abortItem") {
        this.edit = false;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      }
    },

    safeItem() {
      const fields = {
        title: this.title ?? "",
        titleSize: this.titleSize ?? "1",
        columnSize: this.columnSize ?? "12",
      };

      // add sectionData key/values to fields
      if (this.sectionData) {
        Object.keys(this.sectionData).forEach((key) => {
          fields[key] = this.sectionData[key];
        });
      }
      let emitAction = {
        action: "update",
        sliceId: this.sliceid,
        prio: this.prio,
        fields: fields,
      };

      this.edit = false;

      this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
      this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
    },
  },
};
</script>

<style scoped>
fieldset {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
