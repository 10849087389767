<template>
  <v-card class="mb-4 grey lighten-5">
    <SliceHeader
      :edit="edit"
      :someSliceInEdit="someSliceInEdit"
      :componentName="element.componentName"
    />

    <v-card-text class="component">
      <div v-if="edit" class="component-edit">
        <v-container>
          <v-md-editor
            v-model="textLong"
            height="400px"
            left-toolbar="h bold italic | ul ol | emoji"
            right-toolbar="preview fullscreen"
            :disabled-menus="['h/h1']"
            mode="edit"
          ></v-md-editor>
        </v-container>

        <div class="d-flex mb-2">
          <v-checkbox
            v-model="isCentered"
            true-value="1"
            false-value="0"
            label="Zentriert"
          ></v-checkbox>
        </div>

        <SectionSettings
          :data="element.data"
          @result="sectionData = $event"
        ></SectionSettings>
      </div>

      <div v-else class="component-preview">
        <!-- 
            In der Anleitung so: <vue-markdown>mein text</vue-markdown>
            Muss ich so machen, da textLong sonst nicht geupdated wird: <vue-markdown :source="element.data.textLong"></vue-markdown>
            -> https://github.com/miaolz123/vue-markdown
            VueMarkdown has a default slot which is used to write the markdown source.
            TIP: The default slot only renders once at the beginning, and it will overwrite the prop of source!
          -->
        <vue-markdown
          v-if="element.data.textLong"
          :source="element.data.textLong"
          :class="element.data.isCentered === '1' ? 'text-center' : ''"
        ></vue-markdown>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-chip
        v-if="element.data.sliceIdent"
        x-small
        class="mt-4 float-right grey--text"
        color="grey lighten-2"
      >
        <v-icon x-small left>mdi-information</v-icon>Custom class: .slice_{{
          element.data.sliceIdent
        }}
      </v-chip>

      <v-spacer></v-spacer>

      <div v-if="edit" class="component-onedit-buttons">
        <EditButtons
          @emit="editbuttonAction"
          ShowSafeButton="true"
          ShowAbortButton="true"
        ></EditButtons>
      </div>
      <div v-else class="component-onpreview-buttons">
        <EditButtons
          v-if="!someSliceInEdit"
          @emit="editbuttonAction"
          ShowEditButton="true"
          ShowDeleteButton="true"
        ></EditButtons>

        <EditButtons
          v-else
          @emit="editbuttonAction"
          Disabled="true"
          ShowEditButton="true"
          ShowDeleteButton="true"
        ></EditButtons>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import SliceHeader from "@/components/pages/contentelements/components/ui/sliceHeader";
import EditButtons from "@/components/ui/EditButtons";
import VueMarkdown from "vue-markdown/src/VueMarkdown"; // neuer Weg (s. oben)
import SectionSettings from "@/components/pages/contentelements/components/SectionSettings";

export default {
  name: "Textblock",

  components: {
    SliceHeader,
    EditButtons,
    VueMarkdown,
    SectionSettings,
  },

  data() {
    return {
      lazy: true,
      valid: true,
      textLong: "",
      isCentered: 0,
      edit: false,
      sectionData: {},
    };
  },

  created() {
    this.textLong = this.$props.element.data.textLong ?? "";
    this.isCentered = this.$props.element.data.isCentered ?? "0";
    this.$emit("sliceOnEdit", false); // emit to parent-component
  },

  methods: {
    /* Clicks werden via $emit action-parameter von der Kindkomponente <EditButtons></EditButtons> übergeben. 
    Der emit-Parameter (value, Object (action+itemId)) wird hier ausgewertet ...
    */

    editbuttonAction(value) {
      if (value.action === "editItem") {
        /* update items may could have changed 
        this avoids from keeping unsaved changes after abort */

        if (this.element.data.textLong && this.element.data.textLong !== null) {
          this.textLong = this.element.data.textLong;
        } else {
          this.textLong = "";
        }

        this.edit = true;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      } else if (value.action === "deleteItem") {
        let emitAction = {
          action: "delete",
          sliceId: this.sliceid,
          prio: this.prio,
        };

        this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
      } else if (value.action === "safeItem") {
        this.safeItem();
      } else if (value.action === "abortItem") {
        this.edit = false;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      }
    },

    safeItem() {
      const fields = {
        textLong: this.textLong ?? "",
        isCentered: this.isCentered ?? "0",
        sectionBackgroundColor: this.sectionBackgroundColor ?? "",
        sectionFontColor: this.sectionFontColor ?? "",
      };

      // add sectionData key/values to fields
      if (this.sectionData) {
        Object.keys(this.sectionData).forEach((key) => {
          fields[key] = this.sectionData[key];
        });
      }

      let emitAction = {
        action: "update",
        sliceId: this.sliceid,
        prio: this.prio,
        fields: fields,
      };

      this.edit = false;
      this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
      this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
    },
  },

  props: ["sliceid", "element", "someSliceInEdit", "prio"],
};
</script>

<style scoped>
fieldset {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
