<template>
  <div>
    <span v-if="mediaUuid">
      <MediaItemPreview
        :uuid="mediaUuid"
        :showCaption="true"
        aspectRatio=""
        width="300"
        :key="componentKey"
      />
    </span>

    <v-text-field
      class="align-self-center p0 m0"
      v-model="mediaUuid"
      type="text"
      disabled
    ></v-text-field>

    <v-btn
      class="white--text mx-1"
      depressed
      fab
      x-small
      color="red"
      :disabled="mediaUuid ? false : true"
      @click="
        mediaUuid = '';
        $emit('mediaAdded', mediaUuid);
      "
    >
      <v-icon dark> mdi-trash-can </v-icon>
    </v-btn>

    <v-btn
      depressed
      color="green"
      class="white--text"
      @click="
        addMediaDialog = true;
        componentKey += 1;
      "
    >
      {{ $t("plugins.mediapool.title") }}
      <v-icon right dark> mdi-folder-multiple-image </v-icon>
    </v-btn>

    <v-dialog
      v-model="addMediaDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn
            icon
            dark
            @click="addMediaDialog = false"
            :disabled="uploading ? true : false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title>
            {{ $t("plugins.mediapool.title") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <Mediapool
            selectable="one"
            :selectmode="true"
            :dialogMode="true"
            :key="componentKey"
            @itemSelected="itemSelected"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import Mediapool from "@/views/tech/mediapool/Mediapool";
import MediaItemPreview from "@/components/mediapool/MediaItemPreview";
export default {
  name: "AddMediaFromMediapool",

  components: {
    MediaItemPreview,
    Mediapool,
  },

  props: ["mediaUuid"],

  created: async function () {},

  data() {
    return {
      componentKey: 0,
      addMediaDialog: false,
      uploading: false,
    };
  },

  methods: {
    itemSelected(item) {
      this.componentKey += 1;
      this.$emit("mediaAdded", item.uuid);
      this.addMediaDialog = false;
    },
  },

  mixins: [apiRequest],
};
</script>
