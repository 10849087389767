<template>
  <div>
    <v-card class="mb-4 grey lighten-5">
      <SliceHeader
        :edit="edit"
        :someSliceInEdit="someSliceInEdit"
        :componentName="element.componentName"
      />

      <v-card-text class="component">
        <div v-if="edit" class="component-edit">
          <template v-for="(item, index) in selected">
            <div :key="item.id">
              <Item
                :items="items"
                :item="item"
                @remove="removeItem(index)"
                :key="componentKey"
              />
            </div>
          </template>

          <v-btn
            v-if="companies_id"
            depressed
            class="my-4"
            color="primary"
            @click="addDialog = true"
          >
            Vertrag hinzufügen
          </v-btn>

          <span v-else>
            <p>
              <strong class="red--text">
                Es ist noch nicht möglich einen Vertrag hinzuzufügen.
              </strong>
            </p>
            <p>
              Erst, wenn das Landingpage-Template einer Kampagne zugewiesen
              wurde, können Verträge hinzugefügt werden, da erst dann eine
              Firmenzuweisung möglich ist.
            </p>
          </span>
        </div>

        <div v-else class="component-preview">
          <!-- Damit beim Abort nicht der mutierte Wert stehen bleibt, wird hier definitiv auf das Array verwiesen -->
          <ItemPreview
            v-for="item in selected"
            :key="item.id"
            :item="item"
            :items="items"
          />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-chip
          v-if="element.data.sliceIdent"
          x-small
          class="mt-4 float-right grey--text"
          color="grey lighten-2"
        >
          <v-icon x-small left>mdi-information</v-icon>Custom class: .slice_{{
            element.data.sliceIdent
          }}
        </v-chip>

        <v-spacer></v-spacer>

        <div v-if="edit" class="component-onedit-buttons">
          <EditButtons
            @emit="editbuttonAction"
            :ShowSafeButton="companies_id ? true : false"
            ShowAbortButton="true"
          ></EditButtons>
        </div>
        <div v-else class="component-onpreview-buttons">
          <EditButtons
            v-if="!someSliceInEdit"
            @emit="editbuttonAction"
            ShowEditButton="true"
            ShowDeleteButton="true"
          ></EditButtons>

          <EditButtons
            v-else
            @emit="editbuttonAction"
            Disabled="true"
            ShowEditButton="true"
            ShowDeleteButton="true"
          ></EditButtons>
        </div>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="addDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      :key="componentKey"
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title>Wähle Verträge</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="addDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <SelectContract
            :companies_id="companies_id"
            :selected="selected"
            :key="componentKey"
            @selection="selected = $event"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import SliceHeader from "@/components/pages/contentelements/components/ui/sliceHeader";
import EditButtons from "@/components/ui/EditButtons";
import Item from "@/components/pages/contentelements/components/contracts/Item";
import ItemPreview from "@/components/pages/contentelements/components/contracts/ItemPreview";
import SelectContract from "@/components/pages/contentelements/components/contracts/SelectContract";

export default {
  name: "Contract",

  components: {
    SliceHeader,
    EditButtons,
    Item,
    ItemPreview,
    SelectContract,
  },

  // * Da die Komponente Company-Basiert ist, werden hier die companies_id und campaigns_id übergeben
  props: [
    "sliceid",
    "element",
    "someSliceInEdit",
    "prio",
    "companies_id",
    "campaigns_id",
  ],

  data() {
    return {
      componentKey: 0,
      addDialog: false,
      selected: this.splitSelected(), // convert prop (string) to an array
      cleanSelection: this.splitSelected(),
      lazy: true,
      valid: true,
      items: [],
      title: "",
      titleSize: "1",
      edit: false,
      titleSizes: ["1", "2", "3", "4", "5"],
    };
  },

  created() {
    this.$emit("sliceOnEdit", false); // emit to parent-component

    if (this.companies_id) {
      this.getData();
    }
  },

  methods: {
    splitSelected() {
      if (this.element.data.foreign_ids) {
        return this.element.data.foreign_ids.split(",");
      }
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(
        `pay/contracts?filter[companies_id]=${this.companies_id}`
      );
      this.fetchingData = false;

      if (response.status === 200) {
        this.items = response.data.data;
      }
    },

    removeItem(index) {
      this.selected.splice(index, 1);
      this.componentKey += 1;
    },

    /* Clicks werden via $emit action-parameter von der Kindkomponente <EditButtons></EditButtons> übergeben. 
    Der emit-Parameter (value, Object (action+itemId)) wird hier ausgewertet ...
    */

    editbuttonAction(value) {
      if (value.action === "editItem") {
        /* update items may could have changed 
        this avoids from keeping unsaved changes after abort */
        this.title = this.element.data.title;
        this.titleSize = this.element.data.titleSize;

        this.edit = true;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      } else if (value.action === "deleteItem") {
        let emitAction = {
          action: "delete",
          sliceId: this.sliceid,
          prio: this.prio,
        };

        this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
      } else if (value.action === "safeItem") {
        this.safeItem();
      } else if (value.action === "abortItem") {
        this.edit = false;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      }
    },

    safeItem() {
      /* emit only the fields that gets updated */
      let emitAction = {
        action: "update",
        sliceId: this.sliceid,
        prio: this.prio,
        fields: {
          foreign_ids: this.selected,
        },
      };

      this.edit = false;

      this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
      this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
    },
  },

  mixins: [apiRequest],
};
</script>

<style scoped>
fieldset {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
