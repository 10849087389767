<template>
  <v-card class="mb-4 grey lighten-5">
    <v-card-text>
      <v-data-table
        v-model="selection"
        :headers="headers"
        :items="items"
        item-key="id"
        selectable-key="isActive"
        show-select
        dense
      >
      </v-data-table>

      <v-btn depressed class="my-4" color="primary" @click="itemselected">
        Auswahl ({{ countSelection() }}) übernehmen
      </v-btn>

      <!--  @item-selected="itemselected" -->
    </v-card-text>

    <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "SelectCategories",

  components: {},

  props: ["selected", "items"],

  data() {
    return {
      selection: this.selected ?? [],
      fetchingData: false,
      lazy: true,
      valid: true,
      genArray: [],
      edit: false,
      shopId: null,
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "title" },
        { text: "Shop", value: "shopTitle" },
      ],
    };
  },

  created() {
    // ich muss das Array selection aufbereiten

    if (this.selection && this.selection !== "") {
      this.selection.forEach((item) => {
        const el = { id: item };
        this.genArray.push(el);
      });

      this.selection = this.genArray;
    }
  },

  methods: {
    countSelection() {
      if (!this.selection) return "0";
      return this.selection.length;
    },

    itemselected() {
      /* warum auch immer wird getriggert, bevor usersCompanies mutiert wurde.
      Deswegen warte ich 100ms */
      const that = this;
      setTimeout(function () {
        // ich muss es in ein anderes format bringen
        /* 
        [ { "id": "1" }, { "id": "2" }, { "id": "3" }, { "id": "4" } ]

        [{
          id: 1
        }] */

        let newFormat = [];

        that.selection.forEach((item) => {
          newFormat.push(item.id);
        });

        console.log(newFormat);
        that.$emit("selection", newFormat);
      }, 100);
    },
  },

  mixins: [apiRequest],
};
</script>
