<template>
  <v-card class="mb-4 grey lighten-5">
    <SliceHeader
      :edit="edit"
      :someSliceInEdit="someSliceInEdit"
      :componentName="element.componentName"
    />

    <v-card-text class="component">
      <div v-if="edit" class="component-edit">
        <v-form v-model="valid" class="d-block" id="form" ref="form">
          <v-row>
            <v-col cols="12" md="6">
              <h2 class="mb-4">
                {{ isFullsize === "1" ? "Desktop-Bild" : "Bild" }}
              </h2>
              <AddMediaFromMediapool
                :mediaUuid="mediaUuid"
                @mediaAdded="mediaUuid = $event"
              />
            </v-col>

            <v-col v-if="isFullsize === '1'" cols="12" md="6">
              <h2 class="mb-4">Mobile-Bild</h2>
              <AddMediaFromMediapool
                :mediaUuid="mediaMobileUuid"
                @mediaAdded="mediaMobileUuid = $event"
              />
            </v-col>

            <v-col cols="12" class="mt-4">
              <h2>Bildbreite</h2>
            </v-col>

            <v-col cols="12" md="6">
              <v-checkbox
                v-model="isFullsize"
                false-value="0"
                true-value="1"
                :label="`Bild in voller Breite anzeigen`"
              ></v-checkbox>
            </v-col>

            <v-col v-if="!isFullsize || isFullsize === '0'" cols="12" md="6">
              <v-select
                class="flex-grow-1 mx-2"
                v-model="columnSize"
                label="Breite des Bildes"
                :items="[
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                  '6',
                  '7',
                  '8',
                  '9',
                  '10',
                  '11',
                  '12',
                ]"
                required
              ></v-select>
            </v-col>

            <template v-if="isFullsize === '1'">
              <v-col cols="12">
                <h2>Text auf Bild</h2>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  class="flex-grow-1 mx-2"
                  v-model="title"
                  :counter="50"
                  label="Titel"
                  required
                ></v-text-field>

                <v-textarea
                  class="flex-grow-1 mx-2"
                  v-model="textShort"
                  :counter="200"
                  label="Text"
                  required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <h2>Button</h2>
              </v-col>

              <v-col cols="12">
                <v-checkbox
                  v-model="useButton"
                  false-value="0"
                  true-value="1"
                  :label="`Button verwenden`"
                ></v-checkbox>
              </v-col>

              <template v-if="useButton === '1'">
                <v-col cols="12" md="6">
                  <v-text-field
                    class="flex-grow-1 mx-2"
                    v-model="buttonText"
                    :counter="20"
                    label="Text auf Button"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    v-model="buttonColorClass"
                    item-text="class"
                    item-value="class"
                    label="Farbe des Buttons*"
                    :items="colorClasses"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="tmplAction"
                    false-value="0"
                    true-value="1"
                    :label="`Template-Action`"
                    hint="Die im Template definierte Action wird ausgeführt. z. B. wird das Formular geöffnet."
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    class="flex-grow-1 mx-2"
                    v-model="targetUrl"
                    label="URL"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    class="flex-grow-1 mx-2"
                    v-model="anchorTarget"
                    label="Anker-Ziel"
                    :persistent-hint="true"
                    hint="Die Klasse der Content-Section, zu der gescrollt werden soll."
                  ></v-text-field>
                </v-col>
              </template>
            </template>
          </v-row>
        </v-form>

        <SectionSettings
          :data="element.data"
          @result="sectionData = $event"
        ></SectionSettings>
      </div>

      <div v-else class="component-preview">
        <div class="box">
          <v-row no-gutters justify="center">
            <v-col :cols="element.data.columnSize">
              <v-img
                v-if="element.data.mediaUrl"
                :src="element.data.mediaUrl"
              ></v-img>
              <span v-else>Kein Bild ausgewählt</span>
              <span class="caption">
                <template v-if="element.data.isFullsize === '1'">
                  <strong>Breite:</strong> Vollbild<br />
                  <span v-if="element.data.title"
                    ><strong>Überschrift:</strong> {{ element.data.title }}<br
                  /></span>
                  <span v-if="element.data.textShort">
                    <strong>Text:</strong> {{ element.data.textShort }}
                  </span>
                  <span v-if="element.data.useButton">
                    <br /><strong>Button:</strong> Ja
                  </span>
                </template>
                <template v-else>
                  <strong>Breite:</strong> {{ element.data.columnSize }}
                </template>
              </span>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-chip
        v-if="element.data.sliceIdent"
        x-small
        class="mt-4 float-right grey--text"
        color="grey lighten-2"
      >
        <v-icon x-small left>mdi-information</v-icon>Custom class: .slice_{{
          element.data.sliceIdent
        }}
      </v-chip>

      <v-spacer></v-spacer>

      <div v-if="edit" class="component-onedit-buttons">
        <EditButtons
          @emit="editbuttonAction"
          ShowSafeButton="true"
          ShowAbortButton="true"
        ></EditButtons>
      </div>
      <div v-else class="component-onpreview-buttons">
        <EditButtons
          v-if="!someSliceInEdit"
          @emit="editbuttonAction"
          ShowEditButton="true"
          ShowDeleteButton="true"
        ></EditButtons>

        <EditButtons
          v-else
          @emit="editbuttonAction"
          Disabled="true"
          ShowEditButton="true"
          ShowDeleteButton="true"
        ></EditButtons>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import SliceHeader from "@/components/pages/contentelements/components/ui/sliceHeader";
import EditButtons from "@/components/ui/EditButtons";
import AddMediaFromMediapool from "@/components/ui/AddMediaFromMediapool";
import SectionSettings from "@/components/pages/contentelements/components/SectionSettings";
export default {
  name: "SimpleImage",

  components: {
    SliceHeader,
    EditButtons,
    AddMediaFromMediapool,
    SectionSettings,
  },

  data() {
    return {
      lazy: true,
      valid: true,
      title: "",
      textShort: "",
      mediaUuid: null,
      mediaMobileUuid: null,
      isResizable: "0",
      isFullsize: "0",
      columnSize: "12",
      useButton: "0",
      buttonText: "",
      buttonColorClass: "primary",
      tmplAction: false,
      targetUrl: null,
      anchorTarget: null,
      edit: false,
      sectionData: {},
      colorClasses: ["primary", "secondary", "green", "red"],
    };
  },

  created() {
    console.log("image created");
    this.title = this.$props.element.data.title ?? "";
    this.textShort = this.$props.element.data.textShort ?? "";
    this.mediaUuid = this.$props.element.data.mediaUuid ?? "";
    this.mediaMobileUuid = this.$props.element.data.mediaMobileUuid ?? "";
    this.isResizable = this.$props.element.data.isResizable ?? "0";
    this.isFullsize = this.$props.element.data.isFullsize ?? "0";
    this.columnSize = this.$props.element.data.columnSize ?? "12";
    this.useButton = this.$props.element.data.useButton ?? "0";
    this.buttonText = this.$props.element.data.buttonText ?? "";
    this.buttonColorClass =
      this.$props.element.data.buttonColorClass ?? "primary";
    this.tmplAction = this.$props.element.data.tmplAction ?? false;
    this.targetUrl = this.$props.element.data.targetUrl ?? "";
    this.anchorTarget = this.$props.element.data.anchorTarget ?? "";
    this.$emit("sliceOnEdit", false); // emit to parent-component
  },

  methods: {
    /* Clicks werden via $emit action-parameter von der Kindkomponente <EditButtons></EditButtons> übergeben. 
    Der emit-Parameter (value, Object (action+itemId)) wird hier ausgewertet ...
    */

    editbuttonAction(value) {
      if (value.action === "editItem") {
        /* update items may could have changed 
        this avoids from keeping unsaved changes after abort */
        this.title = this.element.data.title;
        this.textShort = this.element.data.textShort;
        this.mediaUuid = this.element.data.mediaUuid;
        this.mediaMobileUuid = this.element.data.mediaMobileUuid;
        this.isResizable = this.element.data.isResizable;
        this.isFullsize = this.element.data.isFullsize;
        this.columnSize = this.element.data.columnSize;
        this.useButton = this.element.data.useButton;
        this.buttonText = this.element.data.buttonText;
        this.buttonColorClass = this.element.data.buttonColorClass;
        this.tmplAction = this.element.data.tmplAction;
        this.targetUrl = this.element.data.targetUrl;
        this.anchorTarget = this.element.data.anchorTarget;
        this.edit = true;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      } else if (value.action === "deleteItem") {
        let emitAction = {
          action: "delete",
          sliceId: this.sliceid,
          prio: this.prio,
        };

        this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
      } else if (value.action === "safeItem") {
        this.safeItem();
      } else if (value.action === "abortItem") {
        this.edit = false;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      }
    },

    safeItem() {
      const fields = {
        title: this.title ?? "",
        textShort: this.textShort ?? "",
        mediaUuid: this.mediaUuid ?? "",
        mediaMobileUuid: this.mediaMobileUuid ?? "",
        isResizable: this.isResizable ?? "0",
        isFullsize: this.isFullsize ?? "0",
        columnSize: this.columnSize ?? "12",
        useButton: this.useButton ?? "0",
        buttonText: this.buttonText ?? "",
        buttonColorClass: this.buttonColorClass ?? "primary",
        tmplAction: this.tmplAction ?? false,
        targetUrl: this.targetUrl ?? "",
        anchorTarget: this.anchorTarget ?? "",
      };

      // add sectionData key/values to fields
      if (this.sectionData) {
        Object.keys(this.sectionData).forEach((key) => {
          fields[key] = this.sectionData[key];
        });
      }

      let emitAction = {
        action: "update",
        sliceId: this.sliceid,
        prio: this.prio,
        fields: fields,
      };

      this.edit = false;

      this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
      this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
    },
  },

  props: ["sliceid", "element", "someSliceInEdit", "prio"],
};
</script>
