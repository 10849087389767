<template>
  <div>
    <v-card class="my-1">
      <v-toolbar dense dark>
        <v-toolbar-title>Spalte</v-toolbar-title>
        <v-spacer />

        <v-btn
          fab
          dark
          elevation="0"
          x-small
          color="blue"
          class="white--text mx-1"
          @click="editColumn = true"
        >
          <v-icon dark> mdi-pencil </v-icon>
        </v-btn>

        <v-btn
          fab
          dark
          elevation="0"
          x-small
          color="red"
          class="white--text mx-1"
          @click="askDelete(data.id)"
        >
          <v-icon dark> mdi-trash-can </v-icon>
        </v-btn>

        <v-icon class="draghandle"> mdi-drag </v-icon>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12" v-if="data.mediaUrl">
            <v-img height="200" width="250" :src="data.mediaUrl"></v-img>
          </v-col>

          <v-col v-if="data.icon && data.icon !== 'null'" cols="12">
            <v-icon large>
              {{ data.icon }}
            </v-icon>
          </v-col>

          <v-col v-if="data.headline" cols="12" class="text-h5">
            {{ data.headline }}
          </v-col>

          <v-col v-if="data.textLong" cols="12">
            {{ data.textLong }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="editColumn"
      max-width="1000px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="editColumn = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> Spalte bearbeiten </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <EditColumn :data="data" @updated="columnUpdated" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <AreYouSureDialog
      title="Diese Spalte löschen?"
      text="Möchtest Du dieses Spalte tatsächlich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden."
      :dialog="safeguardingDialog"
      @dialogEmit="safeguarding"
    />
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog";
import EditColumn from "@/components/pages/contentelements/components/columns/EditColumn";
export default {
  name: "Column",

  components: { AreYouSureDialog, EditColumn },

  props: {
    data: { type: Object, required: true },
    index: { type: Number, required: true },
    componentId: { type: String, required: true },
  },

  data() {
    return {
      pageid: this.id,
      addLinkDialog: false,
      componentKey: 0,
      editColumn: false,
      safeguardingDialog: false,
    };
  },

  watch: {},

  methods: {
    columnUpdated() {
      this.componentKey += 1;
      this.editColumn = false;
    },

    safeguarding(value) {
      this.safeguardingDialog = false;

      if (value) {
        this.deleteItem();
      }
    },

    askDelete(value) {
      this.itemToDelete = value;
      this.safeguardingDialog = true;
    },

    async deleteItem() {
      const url = `componentsColumnsData/${this.data.columns_id}/${this.data.id}`;
      let response = await this.deleteRequest(url); // await data from mixin

      if (response.status === 200) {
        this.$emit("deleted");
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Der Datensatz wurde gelöscht";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
