<template>
  <v-card class="mb-4 grey lighten-5">
    <SliceHeader
      :edit="edit"
      :someSliceInEdit="someSliceInEdit"
      :componentName="element.componentName"
    />

    <v-card-text class="component">
      <div v-if="edit" class="component-edit">
        <v-form v-model="valid" class="d-block" id="form" ref="form">
          <fieldset class="px-5 py-5 mb-2">
            <div class="d-flex mb-2">
              <v-text-field
                class="flex-grow-1 mx-2"
                v-model="title"
                :counter="50"
                label="Überschrift"
                required
              ></v-text-field>
              <v-select
                class="flex-grow-1 mx-2"
                v-model="titleSize"
                item-text="size"
                item-value="size"
                label="Größe der Überschrift*"
                :items="titleSizes"
                required
              ></v-select>
            </div>

            <div class="d-flex mb-2">
              <v-md-editor
                v-model="textLong"
                height="400px"
                left-toolbar="h bold italic | ul ol | emoji"
                right-toolbar="preview fullscreen"
                :disabled-menus="['h/h1']"
                mode="edit"
              ></v-md-editor>
            </div>

            <AddMediaFromMediapool
              :mediaUuid="mediaUuid"
              @mediaAdded="mediaUuid = $event"
            />
          </fieldset>
        </v-form>
      </div>

      <div v-else class="component-preview">
        <div
          class="box pa-7 white--text text-center"
          :style="`background-image:url(${element.data.mediaUrl}); background-position: center center; background-size:cover;min-height:300px;`"
        >
          <component
            v-if="element.data.titleSize && element.data.title"
            :is="'h' + element.data.titleSize"
            >{{ element.data.title }}</component
          >
          <vue-markdown
            v-if="element.data.textLong"
            :source="element.data.textLong"
          ></vue-markdown>
        </div>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-chip
        v-if="element.data.sliceIdent"
        x-small
        class="mt-4 float-right grey--text"
        color="grey lighten-2"
      >
        <v-icon x-small left>mdi-information</v-icon>Custom class: .slice_{{
          element.data.sliceIdent
        }}
      </v-chip>

      <v-spacer></v-spacer>

      <div v-if="edit" class="component-onedit-buttons">
        <EditButtons
          @emit="editbuttonAction"
          ShowSafeButton="true"
          ShowAbortButton="true"
        ></EditButtons>
      </div>
      <div v-else class="component-onpreview-buttons">
        <EditButtons
          v-if="!someSliceInEdit"
          @emit="editbuttonAction"
          ShowEditButton="true"
          ShowDeleteButton="true"
        ></EditButtons>

        <EditButtons
          v-else
          @emit="editbuttonAction"
          Disabled="true"
          ShowEditButton="true"
          ShowDeleteButton="true"
        ></EditButtons>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import SliceHeader from "@/components/pages/contentelements/components/ui/sliceHeader";
import EditButtons from "@/components/ui/EditButtons";
import AddMediaFromMediapool from "@/components/ui/AddMediaFromMediapool";
import VueMarkdown from "vue-markdown/src/VueMarkdown"; // neuer Weg (s. oben)
export default {
  name: "ParallaxHero",

  components: {
    SliceHeader,
    EditButtons,
    VueMarkdown,
    AddMediaFromMediapool,
  },

  data() {
    return {
      lazy: true,
      valid: true,
      title: "",
      titleSize: "1",
      mediaUuid: null,
      textLong: "",
      edit: false,
      titleSizes: ["1", "2", "3", "4", "5"],
      configs: {
        status: false,
        spellChecker: false,
        sanitize: true,
        toolbar: [
          "bold",
          "italic",
          "heading-1",
          "heading-2",
          "heading-3",
          "unordered-list",
          "ordered-list",
          "link",
          "preview",
        ],
      },
    };
  },

  created() {
    this.title = this.$props.element.data.title ?? "";
    this.titleSize = this.$props.element.data.titleSize ?? "";
    this.mediaUuid = this.$props.element.data.mediaUuid ?? "";
    this.textLong = this.$props.element.data.textLong ?? "";
    this.$emit("sliceOnEdit", false); // emit to parent-component
  },

  methods: {
    /* Clicks werden via $emit action-parameter von der Kindkomponente <EditButtons></EditButtons> übergeben. 
    Der emit-Parameter (value, Object (action+itemId)) wird hier ausgewertet ...
    */

    editbuttonAction(value) {
      if (value.action === "editItem") {
        /* update items may could have changed 
        this avoids from keeping unsaved changes after abort */
        this.title = this.element.data.title;
        this.titleSize = this.element.data.titleSize;
        this.mediaUuid = this.element.data.mediaUuid;
        this.textLong = this.element.data.textLong;

        this.edit = true;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      } else if (value.action === "deleteItem") {
        let emitAction = {
          action: "delete",
          sliceId: this.sliceid,
          prio: this.prio,
        };

        this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
      } else if (value.action === "safeItem") {
        this.safeItem();
      } else if (value.action === "abortItem") {
        this.edit = false;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      }
    },

    safeItem() {
      /* emit only the fields that gets updated */
      let emitAction = {
        action: "update",
        sliceId: this.sliceid,
        prio: this.prio,
        fields: {
          title: this.title ?? "",
          titleSize: this.titleSize ?? "",
          mediaUuid: this.mediaUuid ?? "",
          textLong: this.textLong ?? "",
        },
      };

      this.edit = false;

      this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
      this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
    },
  },

  props: ["sliceid", "element", "someSliceInEdit", "prio"],
};
</script>

<style scoped>
fieldset {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
