<template>
  <v-card class="my-1">
    <v-card-text>
      <v-row class="align-center">
        <v-col cols="10">
          <v-row>
            <v-col cols="2">#{{ itemid }}</v-col>
            <v-col cols="10">{{ itemName }}</v-col>
          </v-row>
        </v-col>

        <v-col cols="1">
          <v-btn
            fab
            dark
            x-small
            elevation="0"
            color="red"
            @click="$emit('remove', true)"
          >
            <v-icon dark>
              mdi-trash-can
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Item",
  components: {},
  props: ["item", "items"],
  data() {
    return {
      componentKey: 0,
    };
  },
  created() {},

  methods: {},

  watch: {
    item: function() {
      this.componentKey += 1;
    },
  },

  computed: {
    itemName() {
      let itemName = "";

      this.items.forEach((item) => {
        if (item.id === this.item) {
          itemName = item.title;
        }
      });

      return itemName;
    },

    itemid() {
      let itemid = "";

      this.items.forEach((item) => {
        if (item.id === this.item) {
          itemid = item.id;
        }
      });

      return itemid;
    },
  },
};
</script>
