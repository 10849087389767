<template>
  <v-row>
    <v-col cols="12">
      <draggable
        v-model="data"
        ghost-class="ghost"
        @change="changeElement"
        handle=".draghandle"
        draggable=".is-draggable"
      >
        <template v-if="data.length > 0">
          <div v-for="(col, index) in data" :key="index" class="is-draggable">
            <Column
              :data="col"
              :index="index"
              :componentId="componentId"
              :key="componentKey"
              @deleted="getData"
            />
          </div>
        </template>
      </draggable>
    </v-col>

    <v-col cols="12">
      <v-btn depressed color="green" class="white--text" @click="addColumn()">
        <v-icon left> mdi-plus </v-icon>
        Spalte hinzufügen
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import draggable from "vuedraggable";
import Column from "@/components/pages/contentelements/components/columns/Column";
export default {
  name: "ColumnsDetails",

  components: { draggable, Column },

  props: {
    componentId: { type: String, required: true },
  },

  data() {
    return {
      pageId: this.$route.params.id,
      data: [],
      componentKey: 0,
      draggedItemId: null,
      itemToDelete: null,
      auth: [],
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async addColumn() {
      // POST /componentsColumnsData/{columnID}
      this.preloader = true;
      let response = await this.postRequest(
        `componentsColumnsData/${this.componentId}`
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemAddedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        this.selectedComponent = null;
        this.componentKey += 1;

        // todo Mutate
        this.data.push(response.data.data);
        this.componentKey += 1;
      }
    },

    async getData() {
      // GET /componentsColumnsData/{componentID}
      this.fetchinData = true;
      let response = await this.getRequest(
        `/componentsColumnsData/${this.componentId}`
      );

      if (response.status === 200) {
        this.data = response.data.data;
      }
    },

    emittedTableEvent(obj) {
      if (obj.action === "deleteItem") {
        this.itemToDelete = obj.item;
        this.safeguardingDialog = true;
      }
    },

    mutateData(value) {
      this.data[value.name] = value.value; // mutate
    },

    changeElement: async function (evt) {
      if (evt.moved) {
        /* Element wurde verschoben */
        let newIndex = evt.moved.newIndex;
        let oldIndex = evt.moved.oldIndex;
        let columnId = evt.moved.element.id;

        if (newIndex !== oldIndex) {
          const params = new URLSearchParams();
          params.append("prio", newIndex);

          // patch new prio
          this.preloader = true;
          let response = await this.patchRequest(
            `componentsColumnsData/${this.componentId}/${columnId}`,
            params
          );
          this.preloader = false;

          if (response.status === 200) {
            /* start snackbar */
            this.$root.snackbarGlobal.snackbar = true;
            this.$root.snackbarGlobal.snackbarText = "Gespeichert!";
            this.$root.snackbarGlobal.snackbarColor = "primary";
            this.$root.snackbarGlobal.snackbarTimeout = 5000;
          }
        }
      }
    },
  },
  mixins: [apiRequest],
};
</script>

<style>
.draghandle {
  cursor: pointer;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: rgba(0, 0, 0, 0.01);
}
</style>
