<template>
  <v-card class="mb-4 grey lighten-5">
    <SliceHeader
      :edit="edit"
      :someSliceInEdit="someSliceInEdit"
      :componentName="element.componentName"
    />

    <v-card-text class="component">
      <div v-if="edit" class="component-edit">
        <v-form v-model="valid" class="d-block" id="form" ref="form">
          <fieldset class="px-5 py-5 mb-2">
            <div class="d-flex mb-2">
              <v-text-field
                class="flex-grow-1 mx-2"
                v-model="title"
                :counter="50"
                label="Überschrift"
                required
              ></v-text-field>
              <v-select
                class="flex-grow-1 mx-2"
                v-model="titleSize"
                item-text="size"
                item-value="size"
                label="Größe der Überschrift*"
                :items="titleSizes"
                required
              ></v-select>
            </div>
            <div class="d-flex mb-2">
              <v-select
                class="flex-grow-1 mx-2"
                v-model="columnSize"
                item-text="size"
                item-value="size"
                label="Größe der Spalte*"
                :items="columnSizes"
                required
              ></v-select>
            </div>
            <div class="d-flex mb-2">
              <v-checkbox
                v-model="isCentered"
                true-value="1"
                false-value="0"
                label="Zentriert"
              ></v-checkbox>

              <v-checkbox
                v-model="isBold"
                true-value="1"
                false-value="0"
                label="Fett"
                class="ml-4"
              ></v-checkbox>
            </div>
          </fieldset>
        </v-form>

        <SectionSettings
          :data="element.data"
          @result="sectionData = $event"
        ></SectionSettings>
      </div>

      <div v-else class="component-preview">
        <!-- Damit beim Abort nicht der mutierte Wert stehen bleibt, wird hier definitiv auf das Array verwiesen -->

        <component
          v-if="element.data.titleSize && element.data.title"
          :is="'h' + element.data.titleSize"
          :class="`${element.data.isCentered === '1' ? 'text-center' : ''} ${
            element.data.isBold === '1' ? 'font-weight-bold' : ''
          }`"
          >{{ element.data.title }}</component
        >
      </div>
    </v-card-text>

    <v-card-actions>
      <v-chip
        v-if="element.data.sliceIdent"
        x-small
        class="mt-4 float-right grey--text"
        color="grey lighten-2"
      >
        <v-icon x-small left>mdi-information</v-icon>Custom class: .slice_{{
          element.data.sliceIdent
        }}
      </v-chip>

      <v-spacer></v-spacer>

      <div v-if="edit" class="component-onedit-buttons">
        <EditButtons
          @emit="editbuttonAction"
          ShowSafeButton="true"
          ShowAbortButton="true"
        ></EditButtons>
      </div>
      <div v-else class="component-onpreview-buttons">
        <EditButtons
          v-if="!someSliceInEdit"
          @emit="editbuttonAction"
          ShowEditButton="true"
          ShowDeleteButton="true"
        ></EditButtons>

        <EditButtons
          v-else
          @emit="editbuttonAction"
          Disabled="true"
          ShowEditButton="true"
          ShowDeleteButton="true"
        ></EditButtons>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import SliceHeader from "@/components/pages/contentelements/components/ui/sliceHeader";
import EditButtons from "@/components/ui/EditButtons";
import SectionSettings from "@/components/pages/contentelements/components/SectionSettings";
export default {
  name: "Headline",

  components: {
    SliceHeader,
    EditButtons,
    SectionSettings,
  },

  data() {
    return {
      lazy: true,
      valid: true,
      title: "",
      titleSize: "1",
      isCentered: 0,
      isBold: 0,
      edit: false,
      titleSizes: ["1", "2", "3", "4", "5"],
      columnSizes: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      sectionData: {},
    };
  },

  created() {
    this.title = this.$props.element.data.title ?? "";
    this.titleSize = this.$props.element.data.titleSize ?? "2";
    this.columnSize = this.$props.element.data.columnSize ?? "12";
    this.isCentered = this.$props.element.data.isCentered ?? "0";
    this.isBold = this.$props.element.data.isBold ?? "0";
    this.$emit("sliceOnEdit", false); // emit to parent-component
  },

  methods: {
    /* Clicks werden via $emit action-parameter von der Kindkomponente <EditButtons></EditButtons> übergeben. 
    Der emit-Parameter (value, Object (action+itemId)) wird hier ausgewertet ...
    */

    editbuttonAction(value) {
      if (value.action === "editItem") {
        /* update items may could have changed 
        this avoids from keeping unsaved changes after abort */
        this.title = this.element.data.title;
        this.titleSize = this.element.data.titleSize;
        this.columnSize = this.element.data.columnSize;
        this.isCentered = this.element.data.isCentered;
        this.isBold = this.element.data.isBold;

        this.edit = true;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      } else if (value.action === "deleteItem") {
        let emitAction = {
          action: "delete",
          sliceId: this.sliceid,
          prio: this.prio,
        };

        this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
      } else if (value.action === "safeItem") {
        this.safeItem();
      } else if (value.action === "abortItem") {
        this.edit = false;
        this.$emit("sliceOnEdit", this.edit); // emit to parent-component
      }
    },

    safeItem() {
      const fields = {
        title: this.title ?? "",
        titleSize: this.titleSize ?? "2",
        columnSize: this.columnSize ?? "12",
        isCentered: this.isCentered ?? "0",
        isBold: this.isBold ?? "0",
      };

      // add sectionData key/values to fields
      if (this.sectionData) {
        Object.keys(this.sectionData).forEach((key) => {
          fields[key] = this.sectionData[key];
        });
      }
      let emitAction = {
        action: "update",
        sliceId: this.sliceid,
        prio: this.prio,
        fields: fields,
      };

      this.edit = false;

      this.$emit("sliceAction", emitAction); // emit emitAction Obj to parent-component (where it can be processed)
      this.$emit("sliceOnEdit", this.edit); // emit to parent-component that edit-view has stopped
    },
  },

  props: ["sliceid", "element", "someSliceInEdit", "prio"],
};
</script>

<style scoped>
fieldset {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
