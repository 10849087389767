<template>
  <v-card class="my-1">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <span class="font-weight-bold">
            ID {{ itemId }} — {{ itemName }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Item",
  props: ["item", "items"],
  data() {
    return {};
  },
  created() {},

  methods: {},

  computed: {
    itemName() {
      let itemName = "";
      this.items.forEach((item) => {
        if (item.id === this.item) {
          itemName = item.title;
        }
      });
      return itemName;
    },

    itemId() {
      let itemId = "";

      this.items.forEach((item) => {
        if (item.id === this.item) {
          itemId = item.id;
        }
      });

      return itemId;
    },

    infoText() {
      let obaDesc = "";

      this.items.forEach((item) => {
        if (item.id === this.item) {
          obaDesc = item.infoText;
        }
      });

      return obaDesc;
    },

    personMediaUuid() {
      let personMediaUuid = "";

      this.items.forEach((person) => {
        if (person.id === this.item) {
          personMediaUuid = person.mediaUuid;
        }
      });

      return personMediaUuid;
    },
  },
};
</script>
